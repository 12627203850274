// CompanyJobBoard.jsx
import React, { useState, useEffect, useRef } from 'react';
import { CompanyJobBoardTable } from './company-job-board-table';
import {
  JobBoardContainer,
  JobBoardWrapper,
  Overlay,
} from '../job-board/job-board.styled';
import { colors } from '../../../style-utils/colors';
import { TopMenu } from '../../layout/top-menu/top-menu';
import SendInvitationForm from '../job-board/send-invidation-form';
import { JobOfferWithCompanyData } from '../../../interfaces/job-interfaces';
import { Company } from '../../app-router/component-props';
import { fetchAllItems } from '../../requests/requests';
import { reverseNormalizeCompanyName } from '../../../function-utils/slug-normalizer';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';
import { NotFoundPage } from '../not-found-page/not-found-page';
import { useParams } from 'react-router-dom';

export interface ColorTemplate {
  title_color: string;
  company_title_color: string;
  text_color: string;
  filter_text_color: string;
  filter_color: string;
  button_text_color: string;
  button_color: string;
  background_fill_color: string;
  background_color: string;
}

export interface CompanyJobBoardProps {
  // eslint-disable-next-line
  company?: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  fromPage?: string;
}

export const CompanyJobBoard: React.FC<CompanyJobBoardProps> = ({
  company: propCompany,
  fromPage,
  setUser,
}) => {
  const { companyName } = useParams();
  const [openFormJobId, setOpenFormJobId] = useState<string | null>(null);
  const [job, setJob] = useState<JobOfferWithCompanyData | null>(null);
  const formRef = useRef<HTMLDivElement | null>(null);
  const [colorTemplate, setColorTemplate] = useState<ColorTemplate>({
    title_color: '#010101',
    company_title_color: '#E94E3C',
    text_color: '#010101',
    filter_text_color: '#23201f',
    filter_color: '#F3F2F2',
    button_text_color: '#FDFDFD',
    button_color: '#E94E3C',
    background_fill_color: '#FDFDFD',
    background_color: '#F7F8FA',
  });
  const [company, setCompany] = useState<Company | undefined>(
    propCompany || undefined
  );
  const [isValidCompany, setIsValidCompany] = useState<boolean | null>(null);

  const reverseNormalizedName =
    companyName && reverseNormalizeCompanyName(companyName);

  const handleToggleShareForm = (job: JobOfferWithCompanyData | null) => {
    if (job) {
      setOpenFormJobId(job.id === openFormJobId ? null : job.id);
      setJob(job);
    } else {
      setOpenFormJobId(null);
      setJob(null);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        handleToggleShareForm(null);
      }
    };

    if (openFormJobId) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openFormJobId]);

  const fetchCompanyDataByName = async () => {
    const response = await fetchAllItems(
      `/company-by-name/${reverseNormalizedName}`
    );
    if (!response) {
      throw new Error('Company not found');
    }
    const companyData = response as unknown as Company;
    setCompany(companyData);

    return companyData.id;
  };

  const fetchColorTemplate = async (companyId: string) => {
    try {
      const response = await axios.get<ColorTemplate>(
        `${apiRoute}/color_template/${companyId}`
      );
      if (response.status === 200 && response.data) {
        setColorTemplate(response.data);
      } else {
        console.log('No color template found, using default values.');
      }
    } catch (error) {
      console.error('Error fetching color template:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let companyId: string | null = null;

        if (reverseNormalizedName && fromPage !== 'global') {
          // Scenario 2: Fetch company based on URL parameter
          companyId = await fetchCompanyDataByName();
        } else if (propCompany) {
          // Scenario 1: Use the provided company prop
          companyId = propCompany.id;
        } else if (company) {
          companyId = company.id;
        }

        if (companyId) {
          await fetchColorTemplate(companyId);
          setIsValidCompany(true);
        } else {
          throw new Error('Company data not available');
        }
      } catch (error) {
        setIsValidCompany(false);
        console.error('Error fetching company data:', error);
      }
    };

    fetchData();
  }, [reverseNormalizedName, fromPage, propCompany, company?.id]);

  if (isValidCompany === null) {
    return <FullScreenSpinner />;
  }

  if (isValidCompany === false) {
    return <NotFoundPage />;
  }

  return (
    <>
      {openFormJobId && <Overlay />}
      <JobBoardWrapper>
        <JobBoardContainer
          bgColor={colors.base_white}
          color={colors.base_black}
        >
          <TopMenu
            user={company}
            setUser={setUser}
            companyLogoUrl={company?.profile_picture}
            bigLogo
            navigationUrl={`/${company?.name}`}
            hideUserAndNotification
          />
        </JobBoardContainer>
        {openFormJobId && (
          <div ref={formRef}>
            <SendInvitationForm
              company={company}
              job={job}
              handleToggleShareForm={() => handleToggleShareForm(null)}
              colorTemplate={colorTemplate}
            />
          </div>
        )}
        <CompanyJobBoardTable
          openFormJobId={openFormJobId}
          handleToggleShareForm={handleToggleShareForm}
          company={company}
          colorTemplate={colorTemplate}
          fromPage={fromPage}
        />
      </JobBoardWrapper>
    </>
  );
};
