import styled from 'styled-components';
import { font } from '../../../../../../style-utils/text';
import { colors } from '../../../../../../style-utils/colors';

interface CellProps {
  bgColor?: string;
  fontColor?: string;
  alignCenter?: boolean;
}

export const TableContainer = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`;

export const TableHeader = styled.div`
  display: flex;
  color: white;
  font-weight: 700;
  text-align: center;
  gap: 8px;
  padding: 0px 16px;
`;

export const HeaderCell = styled.div<CellProps>`
  display: flex;
  flex: 1;
  text-align: center;
  font-family: ${font.family};
  background: ${({ bgColor }) => bgColor || colors.primary_100};
  color: ${({ fontColor }) => fontColor || 'white'};
  font-weight: 700;
  padding: 12px 16px;
  border-radius: 4px;
  min-height: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const TableRow = styled.div`
  display: flex;
  gap: 8px;
  padding: 0px 16px;
`;

export const TableCell = styled.div<CellProps>`
  flex: 1;
  padding: 12px 16px;
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
  font-family: ${font.family};
  font-size: 14px;
  color: ${({ fontColor }) => fontColor || colors.base_black};
  background: ${({ bgColor }) => bgColor || 'transparent'};
  font-weight: 700;
  border-radius: 4px;
`;
