import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonsContainer,
  ModalBackground,
  ModalContent,
  Row,
} from '../pages/super-admin/super-admin-main/modal/modal.styled';
import BigLogo from '../../assets/images/logo_full_big.png';
import { CloseIcon } from '../icons/close-icon';
import { Text } from '../../style-utils/text';
import TutorialImage from '../../assets/images/tutorial-img.jpg';

export const AppTutorialModal = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const closeModal = () => {
    sessionStorage.setItem('seenPopUp', 'true');
    setIsModalVisible(false);
  };

  // Showing tutorial on every new session
  // For showing tutorial only once for the user before he clicks cancel, use localStorage after testing
  useEffect(() => {
    const returningUser = sessionStorage.getItem('seenPopUp');
    if (!returningUser) {
      setIsModalVisible(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isModalVisible && (
        <ModalBackground onClick={closeModal}>
          <ModalContent
            // maxWidth="500px"
            maxWidth={windowWidth < 500 ? '90%' : '500px'}
            // maxHeight="739px"
            maxHeight="90%"
            bg="white"
            onClick={(e) => e.stopPropagation()}
          >
            <Row justifyContent="space-between">
              <img src={BigLogo} width={160} />
              <div onClick={closeModal} style={{ cursor: 'pointer' }}>
                <CloseIcon />
              </div>
            </Row>
            <div
              style={{ marginTop: 10, marginBottom: 10, alignSelf: 'center' }}
            >
              <img
                src={TutorialImage}
                style={{
                  borderRadius: 16,
                  maxHeight: '90%',
                  maxWidth: '100%',
                  objectFit: 'contain',
                }}
                alt="Tutorial"
              />
            </div>
            <div>
              <Text
                fontFamily="Lato"
                fontSize={18}
                fontWeight={700}
                lineHeight="21.6px"
                margin="0"
              >
                Welcome to WorWise
              </Text>
              <Text
                fontFamily="Lato"
                fontSize={14}
                fontWeight={400}
                lineHeight="16.8px"
                margin="0"
                pt={4}
              >
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Ducimus corporis quo consequatur quam sunt dolorem, magni,
                adipisci expedita ullam a commodi optio libero in laboriosam
                dicta perspiciatis architecto sint voluptates.
              </Text>
            </div>
            <ButtonsContainer>
              <Button isBack={false}>Start the tour</Button>
            </ButtonsContainer>
          </ModalContent>
        </ModalBackground>
      )}
    </>
  );
};
