// src/components/super-admin/super-admin-tab/modal/AdminModal.tsx

import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Text, font } from '../../../../../style-utils/text';
import {
  ModalBackground,
  ModalContent,
  InputsContainer,
  Row,
  InputAndTextContainer,
  ButtonsContainer,
  Button,
  StyledSelect,
  StyledInput,
  ErrorText,
} from './modal.styled';
import axios from 'axios';
import { fetchAllItems } from '../../../../requests/requests';
import { apiRoute } from '../../../../../constants/api-constants';
import { colors } from '../../../../../style-utils/colors';
import {
  phoneRegex,
  validPhoneNumberErrorLabelText,
} from '../../../../../function-utils/phone-number-validation';

const roles = ['HR', 'Admin', 'Manager', 'Developer'];

interface AdminModalProps {
  onClose: () => void;
  fromPage?: string;
  setPopUp: Dispatch<
    SetStateAction<{ text: string; type: 'success' | 'error' } | null>
  >;
  isEdit?: boolean;
  // eslint-disable-next-line
  adminData?: any;
  companyId?: string;
}

interface Company {
  id: string;
  name: string;
}

export const AdminModal: React.FC<AdminModalProps> = ({
  onClose,
  fromPage,
  setPopUp,
  isEdit = false,
  adminData,
  companyId,
}) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    company_id: '',
    password: '',
    confirmPassword: '',
    role: 'HR',
    phone: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company_id: '',
    password: '',
    confirmPassword: '',
    role: '',
    phone: '',
  });

  useEffect(() => {
    const initializeForm = async () => {
      if (isEdit && adminData) {
        setFormData({
          firstName: adminData.firstName || '',
          lastName: adminData.lastName || '',
          email: adminData.email || '',
          company: adminData.company_id || '',
          company_id: adminData.company_id || '',
          password: '',
          confirmPassword: '',
          role: adminData.account_type || 'HR',
          phone: adminData.phone_number || '',
        });
      } else {
        if (fromPage === 'adminSidebar') {
          setFormData((prevData) => ({
            ...prevData,
            company_id: companyId ?? '',
          }));
        } else {
          try {
            const data = await fetchAllItems('companies');
            setCompanies(data as Company[]);
          } catch (error) {
            console.error('Error fetching companies:', error);
          }
        }
      }
    };

    initializeForm();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompanyId = e.target.value;
    setFormData({
      ...formData,
      company: selectedCompanyId,
      company_id: selectedCompanyId,
    });
  };

  const validateForm = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // eslint-disable-next-line
    const errors: any = {};

    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required.';
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required.';
    }

    if (!emailRegex.test(formData.email)) {
      errors.email = 'Please enter a valid email (e.g., example@mail.com)';
    }

    if (fromPage === 'adminSidebar' || !isEdit) {
      if (formData.phone && !phoneRegex.test(formData.phone)) {
        errors.phone = validPhoneNumberErrorLabelText;
      }
    } else {
      if (!formData.company_id) {
        errors.company_id = 'Company selection is required.';
      }

      if (!formData.password) {
        errors.password = 'Password is required.';
      }

      if (!formData.confirmPassword) {
        errors.confirmPassword = 'Confirm password is required.';
      }

      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match.';
      }
    }

    if (!formData.role) {
      errors.role = 'Role is required.';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      if (isEdit && adminData) {
        // Editing an existing admin
        // eslint-disable-next-line
        const payload: any = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          role: formData.role,
          company_id: formData.company_id,
          phone_number: formData.phone,
        };

        // Only include password fields if they are filled
        if (formData.password) {
          payload.password = formData.password;
        }

        const response = await axios.put(
          `${apiRoute}/update-admin/${adminData.id}`,
          payload
        );

        if (response.status === 200) {
          setPopUp({
            text: `Admin ${formData.firstName} ${formData.lastName} has been successfully updated!`,
            type: 'success',
          });
          onClose();
        } else {
          setPopUp({
            text: `Error: ${response.data.message}`,
            type: 'error',
          });
        }
      } else {
        const response = await axios.post(`${apiRoute}/create-admin`, {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
          role: formData.role,
          company_id: formData.company_id,
          phone_number: formData.phone,
        });

        if (response.status === 200) {
          setPopUp({
            text: `New ${formData.role} has been successfully added!`,
            type: 'success',
          });
          onClose();
        } else {
          setPopUp({
            text: `Error: ${response.data.message}`,
            type: 'error',
          });
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.error('Error:', error);
      setPopUp({
        text: `Error: ${error.response?.data?.message || 'An error occurred.'}`,
        type: 'error',
      });
    }
  };

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <form onSubmit={handleSubmit}>
          <Text
            mt={0}
            mb={16}
            fontSize={28}
            fontFamily={font.family}
            fontWeight={700}
            color={colors.base_black}
          >
            {isEdit ? 'Edit Admin' : 'Add A New Admin'}
          </Text>

          <InputsContainer isOnlyRole={false}>
            <Text
              mt={0}
              mb={16}
              fontSize={24}
              fontFamily={font.family}
              fontWeight={700}
              color={colors.base_black}
            >
              Admin Information
            </Text>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  First name
                </Text>
                <StyledInput
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Enter first name here"
                />
                {formErrors.firstName && (
                  <ErrorText>{formErrors.firstName}</ErrorText>
                )}
              </InputAndTextContainer>
              <InputAndTextContainer>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Last name
                </Text>
                <StyledInput
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Enter last name here"
                />
                {formErrors.lastName && (
                  <ErrorText>{formErrors.lastName}</ErrorText>
                )}
              </InputAndTextContainer>
            </Row>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={8}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Email
                </Text>
                <StyledInput
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter email here"
                />
                {formErrors.email && <ErrorText>{formErrors.email}</ErrorText>}
              </InputAndTextContainer>
              {!isEdit && fromPage !== 'adminSidebar' && (
                <InputAndTextContainer>
                  <Text
                    mt={8}
                    mb={8}
                    fontSize={18}
                    fontFamily={font.family}
                    fontWeight={700}
                    color={colors.base_black}
                  >
                    Company
                  </Text>
                  <StyledSelect
                    name="company"
                    value={formData.company}
                    onChange={handleCompanyChange}
                  >
                    <option value="">Select company</option>
                    {companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </StyledSelect>
                  {formErrors.company_id && (
                    <ErrorText>{formErrors.company_id}</ErrorText>
                  )}
                </InputAndTextContainer>
              )}

              {fromPage === 'adminSidebar' && !isEdit && (
                <InputAndTextContainer>
                  <Text
                    mt={8}
                    mb={8}
                    fontSize={18}
                    fontFamily={font.family}
                    fontWeight={700}
                    color={colors.base_black}
                  >
                    Phone
                  </Text>
                  <StyledInput
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Enter phone number"
                  />
                  {formErrors.phone && (
                    <ErrorText>{formErrors.phone}</ErrorText>
                  )}
                </InputAndTextContainer>
              )}
            </Row>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={8}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Password
                </Text>
                <StyledInput
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter password here"
                />
                {formErrors.password && (
                  <ErrorText>{formErrors.password}</ErrorText>
                )}
              </InputAndTextContainer>
              <InputAndTextContainer>
                <Text
                  mt={8}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Confirm Password
                </Text>
                <StyledInput
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm password"
                />
                {formErrors.confirmPassword && (
                  <ErrorText>{formErrors.confirmPassword}</ErrorText>
                )}
              </InputAndTextContainer>
            </Row>
          </InputsContainer>
          <div style={{ marginTop: '12px' }}></div>
          <InputsContainer isOnlyRole={true}>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Select Role Type
                </Text>
                <StyledSelect
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  {roles.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </StyledSelect>
                {formErrors.role && <ErrorText>{formErrors.role}</ErrorText>}
              </InputAndTextContainer>
              <InputAndTextContainer />
            </Row>
          </InputsContainer>
          <ButtonsContainer>
            <Button isBack={true} onClick={onClose}>
              {isEdit ? 'Cancel' : 'Back to Dashboard'}
            </Button>
            <Button type="submit" isBack={false}>
              {isEdit ? 'Save Changes' : 'Create Admin'}
            </Button>
          </ButtonsContainer>
        </form>
      </ModalContent>
    </ModalBackground>
  );
};
