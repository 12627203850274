import React from 'react';
import { JobOfferWithCompanyData } from '../../../interfaces/job-interfaces';
import { Company } from '../../app-router/component-props';
import {
  BlackInfoText,
  BlueText,
  BlueTextContainer,
  DescriptiveText,
  InfoTextContainer,
  InfoTextWrapper,
  JobDescriptionContainer,
  JobDescriptionTitle,
  JobPosition,
  JobPostInfoContainer,
  RedInfoText,
  SkillItem,
  SkillsList,
  ApplyButton,
  Empty,
  WrapperHeader,
  ApplyContainer,
} from './job-post-info.styled';
import { Date } from './job-postings.styled';
import {
  formatDateToLocal,
  replaceSlashWithDot,
} from '../../../function-utils/utc-date-converter';
import { ColorTemplate } from './company-job-board';

interface JobPostInfoProps {
  job: JobOfferWithCompanyData;
  company?: Company;
  toggleApplyForm: () => void;
  colorTemplate: ColorTemplate;
}

export const JobPostInfo: React.FC<JobPostInfoProps> = ({
  job,
  company,
  toggleApplyForm,
  colorTemplate,
}) => {
  return (
    <JobPostInfoContainer backgroundColor={colorTemplate.background_fill_color}>
      <WrapperHeader>
        <JobPosition textColor={colorTemplate.title_color}>
          {job.job_position}
        </JobPosition>
        <Date color={colorTemplate.title_color}>
          {replaceSlashWithDot(formatDateToLocal(job.createdAt))}
        </Date>
      </WrapperHeader>
      <InfoTextWrapper>
        <InfoTextContainer>
          <RedInfoText textColor={colorTemplate.company_title_color}>
            Company:
          </RedInfoText>{' '}
          <BlackInfoText textColor={colorTemplate.text_color}>
            {company?.name || job.company_name}
          </BlackInfoText>
        </InfoTextContainer>
        <InfoTextContainer>
          <RedInfoText textColor={colorTemplate.company_title_color}>
            Web:
          </RedInfoText>{' '}
          <BlackInfoText textColor={colorTemplate.text_color}>
            {company?.website || job.company_website}
          </BlackInfoText>
        </InfoTextContainer>
      </InfoTextWrapper>
      <BlueTextContainer>
        <BlueText
          backgroundColor={colorTemplate.filter_color}
          textColor={colorTemplate.filter_text_color}
        >
          {job.job_category}
        </BlueText>
        <BlueText
          backgroundColor={colorTemplate.filter_color}
          textColor={colorTemplate.filter_text_color}
        >
          {job.seniority_level}
        </BlueText>
        <BlueText
          backgroundColor={colorTemplate.filter_color}
          textColor={colorTemplate.filter_text_color}
        >
          {job.work_location}
        </BlueText>
      </BlueTextContainer>
      <JobDescriptionContainer>
        <JobDescriptionTitle textColor={colorTemplate.title_color}>
          Job Description:{' '}
        </JobDescriptionTitle>
        <DescriptiveText
          textColor={colorTemplate.text_color}
          dangerouslySetInnerHTML={{ __html: job.job_description }}
        />
      </JobDescriptionContainer>
      <JobDescriptionContainer>
        <JobDescriptionTitle textColor={colorTemplate.title_color}>
          Skills Required:{' '}
        </JobDescriptionTitle>
        <SkillsList>
          {job.skills.map((skill, index) => (
            <SkillItem key={index} color={colorTemplate.text_color}>
              <DescriptiveText textColor={colorTemplate.text_color}>
                {skill}
              </DescriptiveText>
            </SkillItem>
          ))}
        </SkillsList>
      </JobDescriptionContainer>
      <JobDescriptionContainer>
        <JobDescriptionTitle textColor={colorTemplate.title_color}>
          Key Responsibilities:{' '}
        </JobDescriptionTitle>
        <DescriptiveText
          dangerouslySetInnerHTML={{ __html: job.responsibilities }}
          textColor={colorTemplate.text_color}
        ></DescriptiveText>
      </JobDescriptionContainer>
      <JobDescriptionContainer>
        <JobDescriptionTitle textColor={colorTemplate.title_color}>
          Benefits:{' '}
        </JobDescriptionTitle>
        <DescriptiveText
          dangerouslySetInnerHTML={{ __html: job.benefits }}
          textColor={colorTemplate.text_color}
        ></DescriptiveText>
      </JobDescriptionContainer>
      <ApplyContainer>
        <Empty />
        <ApplyButton
          onClick={toggleApplyForm}
          backgroundColor={colorTemplate.button_color}
          textColor={colorTemplate.button_text_color}
        >
          Apply
        </ApplyButton>
      </ApplyContainer>
    </JobPostInfoContainer>
  );
};
