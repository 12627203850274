import React, { useState, useRef, useEffect, useMemo } from 'react';
import { SearchIcon } from '../../../../../icons/search';
import {
  SearchCandidatesAndPositionWrapper,
  SearchCandidatePositionAndButtonWrapper,
  SearchCandidatesAndSearchIconWrapper,
  StyledSearchIcon,
  SearchInputWrapper,
  SearchInput,
  Dropdown,
  DropdownItem,
  StyledCheckbox,
  CompareButton,
  DeselectButton,
  Tag,
  TagLabel,
  TagRemoveButton,
} from './comparison-search.styled';
import { Candidate } from '../interfaces/candidate-interface';
import { Job } from '../interfaces/job-interface'; // Ensure this import is correct
import { BriefcaseIcon } from '../../../../../icons/briefcase';

interface ComparisonSearchProps {
  candidates: Candidate[];
  jobs: Job[];
  selectedCandidates: Candidate[];
  selectedJobPosition: Job | null;
  handleCompare: () => void;
  handleSelectCandidate: (candidate: Candidate) => void;
  handleSelectJobPosition: (job: Job | null) => void;
}

export const ComparisonSearch: React.FC<ComparisonSearchProps> = ({
  candidates,
  jobs,
  selectedCandidates,
  selectedJobPosition,
  handleCompare,
  handleSelectCandidate,
  handleSelectJobPosition,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filteredCandidates, setFilteredCandidates] = useState<Candidate[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [positionInput, setPositionInput] = useState('');
  const [isPositionDropdownVisible, setIsPositionDropdownVisible] =
    useState(false);
  const positionWrapperRef = useRef<HTMLDivElement>(null);

  // Extract unique job positions
  const jobPositions = useMemo(() => {
    const positionsSet = new Set<string>();
    jobs.forEach((job) => {
      if (job.job_position) {
        positionsSet.add(job.job_position);
      }
    });

    return Array.from(positionsSet);
  }, [jobs]);

  // Filter job positions based on input
  const filteredJobPositions = useMemo(() => {
    if (positionInput.trim() === '') {
      return jobPositions;
    }

    return jobPositions.filter((position) =>
      position.toLowerCase().includes(positionInput.toLowerCase())
    );
  }, [positionInput, jobPositions]);

  // Filter candidates based on input
  useEffect(() => {
    if (inputValue.trim() === '') {
      setFilteredCandidates(candidates);
    } else {
      const filtered = candidates.filter((candidate) =>
        `${candidate.first_name} ${candidate.last_name}`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      );
      setFilteredCandidates(filtered);
    }
  }, [inputValue, candidates]);

  // Handle clicks outside the dropdowns to close them
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }

      if (
        positionWrapperRef.current &&
        !positionWrapperRef.current.contains(event.target as Node)
      ) {
        setIsPositionDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Show job positions dropdown when two candidates are selected
  useEffect(() => {
    if (selectedCandidates.length === 2) {
      setIsPositionDropdownVisible(true);
    }
  }, [selectedCandidates]);

  // Show candidates dropdown when a job position is selected
  useEffect(() => {
    if (selectedJobPosition) {
      setIsDropdownVisible(true);
    }
  }, [selectedJobPosition]);

  const handleInputFocus = () => {
    if (selectedCandidates.length < 2) {
      setIsDropdownVisible(true);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (selectedCandidates.length < 2) {
      setIsDropdownVisible(true);
    }
  };

  const handleCandidateSelection = (candidate: Candidate) => {
    if (
      selectedCandidates.length < 2 ||
      selectedCandidates.some((c) => c.id === candidate.id)
    ) {
      handleSelectCandidate(candidate);
    }
  };

  const handleDeselectCandidate = (candidate: Candidate) => {
    handleSelectCandidate(candidate);
  };

  const handleDeselectJob = () => {
    handleSelectJobPosition(null);
  };

  const handlePositionFocus = () => {
    if (!selectedJobPosition) {
      setIsPositionDropdownVisible(true);
    }
  };

  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPositionInput(e.target.value);
    if (!selectedJobPosition) {
      setIsPositionDropdownVisible(true);
    }
  };

  const handleJobSelection = (position: string) => {
    const job = jobs.find((j) => j.job_position === position);

    if (selectedJobPosition && job?.id === selectedJobPosition.id) {
      handleSelectJobPosition(null);
    } else if (job) {
      handleSelectJobPosition(job);
    }
  };

  const handleCompareAndClose = () => {
    handleCompare();
    setIsDropdownVisible(false);
    setIsPositionDropdownVisible(false);
  };

  return (
    <SearchCandidatePositionAndButtonWrapper ref={wrapperRef}>
      <SearchCandidatesAndPositionWrapper>
        {/* Candidates Search */}
        <SearchCandidatesAndSearchIconWrapper>
          <StyledSearchIcon>
            <SearchIcon />
          </StyledSearchIcon>
          <SearchInputWrapper>
            {selectedCandidates.map((candidate) => (
              <Tag key={candidate.id}>
                <TagLabel>
                  {candidate.first_name} {candidate.last_name}
                </TagLabel>
                <TagRemoveButton
                  onClick={() => handleDeselectCandidate(candidate)}
                  aria-label={`Remove ${candidate.first_name} ${candidate.last_name}`}
                >
                  ×
                </TagRemoveButton>
              </Tag>
            ))}
            {selectedCandidates.length < 2 && (
              <SearchInput
                type="text"
                placeholder={
                  selectedCandidates.length > 0 ? '' : 'Search Candidates'
                }
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                aria-haspopup="listbox"
                aria-expanded={isDropdownVisible}
                aria-controls="candidate-dropdown"
                disabled={selectedCandidates.length >= 2}
              />
            )}
          </SearchInputWrapper>
          {isDropdownVisible && selectedCandidates.length < 2 && (
            <Dropdown id="candidate-dropdown" role="listbox">
              {filteredCandidates.length > 0 ? (
                filteredCandidates.map((candidate) => {
                  const isSelected = selectedCandidates.some(
                    (c) => c.id === candidate.id
                  );

                  return (
                    <DropdownItem
                      key={candidate.id}
                      selected={isSelected}
                      onClick={() => handleCandidateSelection(candidate)}
                      role="option"
                      aria-selected={isSelected}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledCheckbox
                          id={`candidate-${candidate.id}`}
                          checked={isSelected}
                          onChange={() => handleCandidateSelection(candidate)}
                          onClick={(e) => e.stopPropagation()}
                          disabled={
                            !isSelected && selectedCandidates.length >= 2
                          }
                        />
                        {candidate.first_name} {candidate.last_name}
                      </div>
                      {isSelected && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <DeselectButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeselectCandidate(candidate);
                            }}
                            aria-label={`Deselect ${candidate.first_name} ${candidate.last_name}`}
                          >
                            ×
                          </DeselectButton>
                        </div>
                      )}
                    </DropdownItem>
                  );
                })
              ) : (
                <DropdownItem selected={false}>
                  No candidates found
                </DropdownItem>
              )}
            </Dropdown>
          )}
        </SearchCandidatesAndSearchIconWrapper>

        {/* Job Positions Search */}
        <SearchCandidatesAndSearchIconWrapper ref={positionWrapperRef}>
          <StyledSearchIcon>
            <BriefcaseIcon />
          </StyledSearchIcon>
          <SearchInputWrapper>
            {selectedJobPosition && (
              <Tag>
                <TagLabel>{selectedJobPosition.job_position}</TagLabel>
                <TagRemoveButton
                  onClick={handleDeselectJob}
                  aria-label={`Remove ${selectedJobPosition.job_position}`}
                >
                  ×
                </TagRemoveButton>
              </Tag>
            )}
            {!selectedJobPosition && (
              <SearchInput
                type="text"
                placeholder="Select Job Position"
                value={positionInput}
                onChange={handlePositionChange}
                onFocus={handlePositionFocus}
                aria-haspopup="listbox"
                aria-expanded={isPositionDropdownVisible}
                aria-controls="position-dropdown"
                disabled={!!selectedJobPosition}
              />
            )}
          </SearchInputWrapper>
          {isPositionDropdownVisible && !selectedJobPosition && (
            <Dropdown id="position-dropdown" role="listbox">
              {filteredJobPositions.length > 0 ? (
                filteredJobPositions.map((position) => {
                  const job: Job | undefined = jobs.find(
                    (j) => j.job_position === position
                  );

                  if (!job) return null;

                  // Since selectedJobPosition is null here, isSelected should be false
                  const isSelected = false;

                  return (
                    <DropdownItem
                      key={job.id} // Use job.id instead of index
                      selected={isSelected}
                      onClick={() => handleJobSelection(position)}
                      role="option"
                      aria-selected={isSelected}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledCheckbox
                          id={`job-${job.id}`}
                          checked={isSelected}
                          onChange={() => handleJobSelection(position)}
                          onClick={(e) => e.stopPropagation()}
                          disabled={!!selectedJobPosition && !isSelected}
                        />
                        {position}
                      </div>
                      {isSelected && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <DeselectButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeselectJob();
                            }}
                            aria-label={`Deselect ${position}`}
                          >
                            ×
                          </DeselectButton>
                        </div>
                      )}
                    </DropdownItem>
                  );
                })
              ) : (
                <DropdownItem selected={false}>
                  No job positions found
                </DropdownItem>
              )}
            </Dropdown>
          )}
        </SearchCandidatesAndSearchIconWrapper>
      </SearchCandidatesAndPositionWrapper>
      <CompareButton
        onClick={handleCompareAndClose}
        disabled={!(selectedCandidates.length === 2 && selectedJobPosition)}
        aria-disabled={
          !(selectedCandidates.length === 2 && selectedJobPosition)
        }
      >
        Compare
      </CompareButton>
    </SearchCandidatePositionAndButtonWrapper>
  );
};
