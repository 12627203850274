import React, { useEffect, useState } from 'react';
import { SignUpData } from '../../hooks/use-auth-forms';
import { loadSubscriptionPlansNew } from '../../../function-utils/subscription-plan-utils';
import { SubscriptionPlanNew } from '../super-admin/subscription-plans/interface';
import { LogInFormContainer, LogInFormWrapper } from '../../login/login.styled';
import { Wrapper } from './change-plan.styled';
import PaymentPlansTableNew from './payment-plans-table-new/payment-plans-table-new';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';

interface ChangePlanSignUpProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser?: (user: any | null) => void;
  refetchUser?: () => Promise<void>;
  setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData?: SignUpData;
  handlePlanSelection?: (plan: string) => void;
}

export const ChangePlanSignUp: React.FC<ChangePlanSignUpProps> = ({
  user,
  setSignUpData,
  signUpData,
  handlePlanSelection,
}) => {
  const [plans, setPlans] = useState<SubscriptionPlanNew[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plans = await loadSubscriptionPlansNew();
        if (isMounted) {
          setPlans(plans);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching subscription plans:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchPlans();

    return () => {
      isMounted = false;
    };
  }, []);

  const setSelectedPlan = (planId: string, amount: number) => {
    handlePlanSelection && handlePlanSelection(planId);
    console.log(`Selected Plan ID: ${planId}, Amount: ${amount}`);
  };

  const defaultSelectedPlanId = user?.subscription_plan_id;

  if (loading) {
    return <FullScreenSpinner />;
  }

  return (
    <LogInFormWrapper id="LoginFormWrapper">
      <LogInFormContainer
        width={100}
        padding={'0'}
        maxWidth="1440px"
        overflow="none"
      >
        <Wrapper padding="0px" isSignUp={true} key={user?.id}>
          <PaymentPlansTableNew
            user={user}
            isSignUp={true}
            setSignUpData={setSignUpData}
            signUpData={signUpData}
            plans={plans}
            setSelectedPlan={setSelectedPlan}
            defaultSelectedPlanId={defaultSelectedPlanId || null}
          />
        </Wrapper>
      </LogInFormContainer>
    </LogInFormWrapper>
  );
};
