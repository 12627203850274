import React, { useRef } from 'react';
import { ComparisonTable } from './comparison-table/comparison-table';
import { ComparisonSearch } from './comparison-search/comparison-search';
import {
  ComparisonModalWrapper,
  TitleAndIconWrapper,
  ModalTitle,
  XButton,
  SearchCandidateTitle,
  ComparisonDescription,
  TableWrapper,
  TableTitle,
  ButtonsWrapper,
  SaveButton,
} from './comparison-modal.styled';
import { Candidate } from './interfaces/candidate-interface';
import { Job } from './interfaces/job-interface';
import { Title } from '../../../blocked-page/blocked-employee-page';
import { RotatingSpinner } from '../../../../spinner-new/spinner-new';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// eslint-disable-next-line
const preloadImages = (element: any) => {
  const imgElements = element.getElementsByTagName('img');
  const promises = [];

  for (const img of imgElements) {
    if (img.src) {
      promises.push(
        new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img.src;
          image.onload = resolve;
          image.onerror = reject;
        })
      );
    }
  }

  return Promise.all(promises);
};

interface ComparisonModalProps {
  jobs: Job[];
  candidates: Candidate[];
  handleComparison: () => void;
  selectedCandidates: Candidate[];
  selectedJobPosition: Job | null;
  handleSelectCandidate: (candidate: Candidate) => void;
  handleSelectJobPosition: (job: Job | null) => void;
  // eslint-disable-next-line
  comparisonData: any;
  loading: boolean;
  error: string;
}

export const ComparisonModal: React.FC<ComparisonModalProps> = ({
  jobs,
  candidates,
  handleComparison,
  selectedCandidates,
  selectedJobPosition,
  handleSelectCandidate,
  handleSelectJobPosition,
  loading,
  error,
  comparisonData,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const generatePDF = () => {
    const input = componentRef.current;

    if (input) {
      preloadImages(input)
        .then(() => {
          return html2canvas(input, { useCORS: true, scale: 2 });
        })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 0.7); // 0.7 is the image quality (0 to 1)

          const pdf = new jsPDF('p', 'mm', 'a4');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          // Check if the content height exceeds the PDF page height
          if (pdfHeight > pdf.internal.pageSize.getHeight()) {
            // Calculate the number of pages
            const totalPages = Math.ceil(
              pdfHeight / pdf.internal.pageSize.getHeight()
            );

            for (let i = 0; i < totalPages; i++) {
              const position = -pdf.internal.pageSize.getHeight() * i;
              pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, pdfHeight);
              if (i < totalPages - 1) {
                pdf.addPage();
              }
            }
          } else {
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
          }

          pdf.save('Company-CV.pdf');
        })
        .catch((error) => {
          console.error('Error generating PDF:', error);
        });
    } else {
      console.error('Component is not mounted or available');
    }
  };

  return (
    <ComparisonModalWrapper>
      <TitleAndIconWrapper>
        <ModalTitle>Candidate comparison</ModalTitle>
        <XButton onClick={handleComparison}>X</XButton>
      </TitleAndIconWrapper>
      <SearchCandidateTitle>Search Candidate</SearchCandidateTitle>
      <ComparisonDescription>
        This comparison provides a concise overview of the key differences
        between candidates.
      </ComparisonDescription>
      <ComparisonSearch
        jobs={jobs}
        candidates={candidates}
        selectedCandidates={selectedCandidates}
        selectedJobPosition={selectedJobPosition}
        handleCompare={handleComparison}
        handleSelectCandidate={handleSelectCandidate}
        handleSelectJobPosition={handleSelectJobPosition}
      />{' '}
      {comparisonData && (
        <TableWrapper>
          <TableTitle>AI-Powered Comparison</TableTitle>
          <div ref={componentRef}>
            <ComparisonTable comparisonData={comparisonData} />
          </div>
          <ButtonsWrapper>
            <SaveButton onClick={generatePDF}>Download</SaveButton>
            <div style={{ paddingLeft: '16px' }}></div>
            <SaveButton>Save</SaveButton>
          </ButtonsWrapper>
        </TableWrapper>
      )}
      {loading && <RotatingSpinner />}
      {error.length > 5 && <Title>{error}</Title>}
    </ComparisonModalWrapper>
  );
};
