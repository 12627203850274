import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';

export const StyledPaymentPlansTableNewMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%; // spored dizajnot width treba da e 1440px koj treba da se postavi na parent poradi kopcinja
  height: fit-content;
`;

export const StyledPaymentPlansTableNewPlanTitlesWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

export const StyledPaymentPlansTableNewPlanTitle = styled.div<{
  rightBorder?: boolean;
  width?: string;
  spaceBetween?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? 'space-between' : 'initial'};
  flex-direction: column;
  border-right: ${({ rightBorder }) =>
    rightBorder ? `0.5px solid ${colors.base_black}` : 'none'};
  flex: ${({ width }) => (width ? '0 0 auto' : '1')};
  width: ${({ width }) => width || 'auto'};
  gap: 24px;
  padding: 8px 16px;
`;

export const StyledPaymentPlansTableNewPlanTitleAndDescriptionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledPaymentPlansTableNewPlanTitleAndFeaturesSeparator = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 0.5px;
  background-color: ${colors.base_black};
  margin-top: 5px;
`;

export const StyledPaymentPlansTableNewPlanFeaturesWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const StyledPaymentPlansTableNewPlanFeature = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  border-bottom: 0.2px solid ${colors.base_black};
`;

export const StyledPaymentPlansTableNewPlanFeatureCell = styled.div<{
  width?: string;
  justifyContent?: string;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  align-items: center;
  flex: ${({ width }) => (width ? '0 0 auto' : '1')};
  width: ${({ width }) => width || 'auto'};
`;

export const SelectButton = styled.button<{ selected: boolean }>`
  background-color: ${({ selected }) =>
    selected ? `${colors.secondary_500}` : `${colors.secondary_200}`};
  color: white;
  padding: 8px 16px 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
`;
