import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, font } from '../../../style-utils/text';
import {
  NewJobApplicationData,
  JobOfferWithCompanyData,
} from '../../../interfaces/job-interfaces';
import {
  ApplicationFormInputs,
  ApplicationFormWrapper,
  ErrorLabel,
  JobApplicationInput,
  StyledJobInput,
  SubmitButtonContainer,
  DoubleInputContainer,
  UploadButton,
  ButtonsContainer,
} from './apply-form.styled';
import { colors } from '../../../style-utils/colors';
import { ButtonContainer, ButtonTitle } from '../../login/login.styled';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { ConfirmPopup } from '../../confirmation-popup/confirm-popup';
import useScreenSize from '../../../style-utils/use-screen-size';
import { Empty } from '../company-job-board/job-post-info.styled';
import { TextEditor } from '../../styled-quill/text-editor';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';
import {
  isValidPhoneNumber,
  validPhoneNumberErrorLabelText,
} from '../../../function-utils/phone-number-validation';

const newJobApplicationInitialState: NewJobApplicationData = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  cv: null,
  portfolio: null,
  additional_files: null,
  linkedin: '',
  github: '',
  message: '',
  referral: '',
  status: 'processing',
  comment: '',
  cv_text: '',
  job_id: '',
  company_id: '',
};

interface ApplyFormProps {
  job?: JobOfferWithCompanyData | null;
}

interface InputRefs {
  first_name: React.RefObject<HTMLInputElement>;
  last_name: React.RefObject<HTMLInputElement>;
  email: React.RefObject<HTMLInputElement>;
  phone_number: React.RefObject<HTMLInputElement>;
  cv: React.RefObject<HTMLInputElement>;
  portfolio: React.RefObject<HTMLInputElement>;
  additional_files: React.RefObject<HTMLInputElement>;
}

export const ApplyForm: React.FC<ApplyFormProps> = ({ job }) => {
  console.log('ApplyForm rendered');

  const [newJobApplication, setNewJobApplication] =
    useState<NewJobApplicationData>(newJobApplicationInitialState);
  const [invalidFieldsInputArray, setInvalidFieldsInputArray] = useState<
    string[]
  >([]);
  const [togglePopup, setTogglePopup] = useState<boolean>(false);
  const [popupMsg, setPopupMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  console.log('State initialized');

  const inputRefs: InputRefs = {
    first_name: useRef<HTMLInputElement>(null),
    last_name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone_number: useRef<HTMLInputElement>(null),
    cv: useRef<HTMLInputElement>(null),
    portfolio: useRef<HTMLInputElement>(null),
    additional_files: useRef<HTMLInputElement>(null),
  };

  const [isCVTooBig, setIsCVTooBig] = useState(false);

  const navigate = useNavigate();
  // const { companyId } = useParams();

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    console.log('Input changed:', name, value);

    if (name === 'cv' || name === 'portfolio' || name === 'additional_files') {
      if (event.target instanceof HTMLInputElement && event.target.files) {
        const file = event.target.files[0];

        if (file) {
          if (name === 'cv') {
            if (file.type !== 'application/pdf') {
              setInvalidFieldsInputArray([...invalidFieldsInputArray, 'cv']);
              setNewJobApplication({ ...newJobApplication, cv: null });

              return;
            }

            setInvalidFieldsInputArray(
              invalidFieldsInputArray.filter((field) => field !== 'cv')
            );

            const fileSizeMB = file.size / 1024 / 1024;
            if (fileSizeMB > 25) {
              setIsCVTooBig(true);
            } else {
              setIsCVTooBig(false);
            }
          }

          setNewJobApplication({ ...newJobApplication, [name]: file });
        }
      }
    } else {
      setNewJobApplication({ ...newJobApplication, [name]: value });
    }
  };

  const handleDivClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const getFileName = (file: File | null) => {
    if (!file) return null;
    const { name } = file;

    return name.length > 10 ? `${name.slice(0, 10)}...` : name;
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    if (isCVTooBig) {
      console.warn('CV is too big');

      return;
    }
    setIsLoading(true);

    if (job) {
      newJobApplication.job_id = job.id;
      if (job.company_id) {
        newJobApplication.company_id = job.company_id;
      }
    }

    const invalidFields = validateNewJobApplicationData(newJobApplication);
    console.log('Invalid fields:', invalidFields);
    setInvalidFieldsInputArray(invalidFields);

    if (invalidFields.length === 0) {
      try {
        const formData = new FormData();

        if (newJobApplication.cv) {
          formData.append('cv', newJobApplication.cv);
        }
        if (newJobApplication.portfolio) {
          formData.append('portfolio', newJobApplication.portfolio);
        }
        if (newJobApplication.additional_files) {
          formData.append(
            'additional_files',
            newJobApplication.additional_files
          );
        }

        formData.append('first_name', newJobApplication.first_name);
        formData.append('last_name', newJobApplication.last_name);
        formData.append('email', newJobApplication.email);
        formData.append('phone_number', newJobApplication.phone_number);
        formData.append('message', newJobApplication.message);
        formData.append('referral', newJobApplication.referral);
        formData.append('job_id', newJobApplication.job_id);
        formData.append('company_id', newJobApplication.company_id);

        console.log('Submitting form data:', formData);

        const response = await axios.post(`${apiRoute}/applicants`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log('Response:', response);

        if (response.status === 200) {
          console.log('Application successfully submitted:', response.data);
          handleTogglePopup(
            'Your application has been submitted successfully. Thank you for applying! We will get back to you soon.'
          );
        } else {
          console.error('Unexpected response status:', response.status);
          handleTogglePopup(
            'Error: Unable to submit your application at this time. Please try again later. If the issue persists, consider contacting our support team for further assistance.'
          );
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          console.error('Axios error:', axiosError.message);
          if (axiosError.response) {
            console.error('Response error:', axiosError.response.data);
          } else if (axiosError.request) {
            console.error('Request error:', axiosError.request);
          }
        } else {
          console.error('Non-Axios error:', error);
        }
      } finally {
        setNewJobApplication(newJobApplicationInitialState);
      }
    } else {
      const refKey = invalidFields[0];
      inputRefs[refKey as keyof InputRefs].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line
  const validateNewJobApplicationData = (data: any) => {
    console.log('Validating job application data:', data);

    const requiredFields = [
      'first_name',
      'last_name',
      'email',
      'phone_number',
      'cv',
    ];
    const emptyFields: string[] = [];

    requiredFields.forEach((field) => {
      if (!data[field] || data[field].length === 0) {
        emptyFields.push(field);
      }

      if (
        field === 'phone_number' &&
        data[field] &&
        !isValidPhoneNumber(data[field])
      ) {
        emptyFields.push('phone_number');
      }
    });

    return emptyFields;
  };

  const handleTogglePopup = (msg: string) => {
    setPopupMsg(msg);
    setTogglePopup(true);
  };

  const handleToggle = (currentState: boolean) => {
    setTogglePopup(currentState);
  };

  const handleBackClick = () => {
    navigate(-1);
    // if (companyId) {
    //   navigate(`/${companyId}/job-board`);
    // }
  };

  const { isTablet } = useScreenSize();

  const handleMessageChange = (value: string) => {
    setNewJobApplication({ ...newJobApplication, message: value });
  };

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  return (
    <ApplicationFormWrapper key={isTablet.toString()} id="job-application-form">
      <ApplicationFormInputs onSubmit={onSubmit}>
        <Text
          fontSize={isTablet ? 18 : 24}
          mb={0}
          mt={0}
          fontWeight={700}
          fontFamily={font.family}
        >
          Job Application
        </Text>
        <Text fontSize={14} mt={4} fontFamily={font.family}>
          Please fill in the form below.
        </Text>
        <Text
          fontSize={14}
          mt={16}
          fontFamily={font.family}
          color={colors.primary_500}
          mb={0}
        >
          Position
        </Text>
        <Text
          fontSize={isTablet ? 18 : 24}
          mt={0}
          fontFamily={font.family}
          color={colors.black}
          fontWeight={700}
          mb={isTablet ? 24 : 32}
        >
          {job?.job_position}
        </Text>
        <DoubleInputContainer>
          <JobApplicationInput double={false}>
            <Text
              mt={0}
              mb={8}
              fontSize={isTablet ? 16 : 18}
              fontWeight={500}
              fontFamily={font.family}
              ref={inputRefs.first_name}
            >
              First Name
            </Text>
            <StyledJobInput
              type="text"
              name="first_name"
              value={newJobApplication.first_name}
              placeholder={'Enter first name'}
              onChange={handleInputChange}
            />
            {invalidFieldsInputArray.includes('first_name') && (
              <ErrorLabel>* Please enter your name</ErrorLabel>
            )}
          </JobApplicationInput>

          <JobApplicationInput double={false}>
            <Text
              mt={isTablet ? 16 : 0}
              mb={8}
              fontSize={isTablet ? 16 : 18}
              fontWeight={500}
              fontFamily={font.family}
              ref={inputRefs.last_name}
            >
              Last Name
            </Text>
            <StyledJobInput
              type="text"
              name="last_name"
              value={newJobApplication.last_name}
              placeholder={'Enter last name'}
              onChange={handleInputChange}
            />
            {invalidFieldsInputArray.includes('last_name') && (
              <ErrorLabel>* Please enter your last name</ErrorLabel>
            )}
          </JobApplicationInput>
        </DoubleInputContainer>
        <DoubleInputContainer>
          <JobApplicationInput double={false}>
            <Text
              mt={24}
              mb={8}
              fontSize={isTablet ? 16 : 18}
              fontWeight={500}
              fontFamily={font.family}
              ref={inputRefs.email}
            >
              Email Address
            </Text>
            <StyledJobInput
              type="text"
              name="email"
              value={newJobApplication.email}
              placeholder={'Enter email address'}
              onChange={handleInputChange}
            />
            {invalidFieldsInputArray.includes('email') && (
              <ErrorLabel>* Please enter your email address</ErrorLabel>
            )}
          </JobApplicationInput>

          <JobApplicationInput double={false}>
            <Text
              mt={24}
              mb={8}
              fontSize={isTablet ? 16 : 18}
              fontWeight={400}
              lineHeight={'normal'}
              fontFamily={font.family}
              ref={inputRefs.phone_number}
            >
              Phone number
            </Text>
            <StyledJobInput
              type="text"
              name="phone_number"
              value={newJobApplication.phone_number}
              placeholder={'Enter phone number'}
              onChange={handleInputChange}
            />
            {invalidFieldsInputArray.includes('phone_number') && (
              <ErrorLabel>* {validPhoneNumberErrorLabelText}</ErrorLabel>
            )}
          </JobApplicationInput>
        </DoubleInputContainer>
        <DoubleInputContainer>
          <JobApplicationInput double={false}>
            <Text
              mt={isTablet ? 24 : 32}
              mb={8}
              fontSize={isTablet ? 16 : 18}
              fontWeight={500}
              fontFamily={font.family}
              ref={inputRefs.cv}
            >
              CV
            </Text>
            <StyledJobInput
              type="file"
              name="cv"
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleInputChange}
              style={{ display: 'none' }}
              ref={inputRefs.cv}
            />
            <UploadButton onClick={() => handleDivClick(inputRefs.cv)}>
              {getFileName(newJobApplication.cv) || 'Attach File (pdf only)'}
            </UploadButton>
            {invalidFieldsInputArray.includes('cv') && (
              <ErrorLabel>* Please upload your CV in PDF format</ErrorLabel>
            )}
            {isCVTooBig && (
              <ErrorLabel>
                The file size exceeds the 25MB limit. Please upload a CV that is
                less than 25MB.
              </ErrorLabel>
            )}
          </JobApplicationInput>

          <JobApplicationInput double={false}>
            <Text
              mt={isTablet ? 24 : 32}
              mb={8}
              fontSize={isTablet ? 16 : 18}
              fontWeight={500}
              fontFamily={font.family}
            >
              Upload Portfolio
            </Text>
            <StyledJobInput
              type="file"
              name="portfolio"
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleInputChange}
              style={{ display: 'none' }}
              ref={inputRefs.portfolio}
            />
            <UploadButton onClick={() => handleDivClick(inputRefs.portfolio)}>
              {getFileName(newJobApplication.portfolio) ||
                'Attach File (pdf, png, jpg)'}
            </UploadButton>
          </JobApplicationInput>

          <JobApplicationInput double={false}>
            <Text
              mt={isTablet ? 24 : 32}
              mb={8}
              fontSize={isTablet ? 16 : 18}
              fontWeight={500}
              fontFamily={font.family}
            >
              Documents
            </Text>
            <StyledJobInput
              type="file"
              name="additional_files"
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleInputChange}
              style={{ display: 'none' }}
              ref={inputRefs.additional_files}
            />
            <UploadButton
              onClick={() => handleDivClick(inputRefs.additional_files)}
            >
              {getFileName(newJobApplication.additional_files) ||
                'Attach File (pdf, png, jpg)'}
            </UploadButton>
          </JobApplicationInput>
        </DoubleInputContainer>

        <JobApplicationInput double={false}>
          <Text
            mt={isTablet ? 24 : 32}
            mb={8}
            fontSize={isTablet ? 16 : 18}
            fontWeight={500}
            fontFamily={font.family}
          >
            Message
          </Text>
          <TextEditor
            height="180px"
            minHeight="180px"
            backgroundColor={colors.neutral_50}
            placeholder="Enter message"
            changeHandler={handleMessageChange}
            defaultValue={newJobApplication.message}
          />
        </JobApplicationInput>
        <JobApplicationInput double={false}>
          <Text
            mt={isTablet ? 24 : 32}
            mb={8}
            fontSize={isTablet ? 16 : 18}
            fontWeight={500}
            fontFamily={font.family}
          >
            Referral
          </Text>
          <StyledJobInput
            type="text"
            name="referral"
            value={newJobApplication.referral}
            placeholder={'Enter referral'}
            onChange={handleInputChange}
          />
        </JobApplicationInput>
        <SubmitButtonContainer>
          {!isTablet ? <Empty /> : null}
          <ButtonsContainer>
            {isTablet ? null : (
              <ButtonContainer
                padding="8px 40px"
                backgroundColor={colors.primary_500}
                mt={isTablet ? 24 : 32}
                ml={0}
                isFontWeight
                type="button"
                onClick={handleBackClick}
              >
                Back
              </ButtonContainer>
            )}

            <ButtonContainer
              width="100%"
              padding="8px 40px"
              backgroundColor={colors.primary_500}
              type="submit"
              mt={isTablet ? 24 : 32}
              isFontWeight
              mr={0}
              justifyContent={isTablet ? 'center' : undefined}
            >
              <ButtonTitle>Submit</ButtonTitle>
            </ButtonContainer>
          </ButtonsContainer>
        </SubmitButtonContainer>
      </ApplicationFormInputs>
      {togglePopup && (
        <ConfirmPopup msgText={popupMsg} handleToggler={handleToggle} />
      )}
    </ApplicationFormWrapper>
  );
};
