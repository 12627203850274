import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const ApplicantsContentWrapper = styled.div`
  padding: 10px 20px;
  background: ${colors.creme_100};
  display: flex;
  flex-direction: column;
  height: 100vh;
  // justify-content: center;
  align-items: center;

  @media (min-width: 1400px) {
    width: 100vw;
  }
`;

export const StyledTable = styled.table`
  //radi ova shto e zakomentirano 100% sidebarot e smalen
  /* width: 100%; */
  /* border-collapse: collapse; */
  /* border: 1px solid ${colors.grey_light}; */
  table-layout: fixed;

  /* border: 1px solid red; */
  /* padding: 4px; */
  background: ${colors.dashboard_light_grey};
  /* overflow-x: auto; */
  width: max-content;

  @media (max-width: 600px) {
    border: 0;
  }
`;

export const StyledThead = styled.thead`
  @media (max-width: 600px) {
    display: none; // Hide header on small screens
  }
`;

export const StyledTr = styled.tr`
  /* width: fit-content; */
  height: 65px;

  @media (max-width: 600px) {
    display: block;
    margin-bottom: 0.625em;
  }
`;

export const StyledTh = styled.th<{
  columnWidth?: string;
  background?: string;
}>`
  padding-block: 15px;
  text-align: center;
  background-color: ${({ background }) => background || colors.primary_500};
  border-radius: 8px;
  color: white;
  /* width: 250px; */
  min-width: ${({ columnWidth }) => columnWidth && columnWidth};
`;
export const StyledTd = styled.td<{
  interviewIsPending?: boolean;
  interviewIsConfirmed?: boolean;
  interviewIsRejected?: boolean;
  textAlign?: string;
  isSubscriptionPlans?: boolean;
}>`
  text-align: ${({ textAlign }) => textAlign || 'left'};
  padding: 0 1em;
  border-radius: 8px;
  background: white;

  ${({ isSubscriptionPlans }) =>
    isSubscriptionPlans &&
    `
    color: var(--Shadow-00, #010101);
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `}

  color: ${({ interviewIsPending }) => interviewIsPending && '#fcba03'};
  color: ${({ interviewIsConfirmed }) => interviewIsConfirmed && '#33b507'};
  color: ${({ interviewIsRejected }) =>
    interviewIsRejected && `${colors.error}`};

  @media (max-width: 600px) {
    display: block;
    text-align: right;

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
  }
`;

export const TitleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContainer = styled.button<{
  mt?: number;
  backgroundColor?: string;
}>`
  background: teal;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'teal'};
  width: fitContent;
  height: 48px;
  border: none;
  transition: all 0.3s ease-out;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : `64px`)};
  box-shadow: 2px 2px 8px 0px #0000001a;

  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p<{ fontColor?: string }>`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 600;
  color: ${({ fontColor }) => fontColor && fontColor};
`;

export const StyledStatusInput = styled.input<{ customSkill?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 96%;
  border: none;
  padding: 14px 16px;
  background: ${colors.dashboard_light_grey};
  font-family: ${font.family};
  width: ${({ customSkill }) => customSkill && '200px'};
  margin-top: ${({ customSkill }) => customSkill && '10px'};

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background: ${colors.dashboard_light_grey};
  }
`;

export const StyledSelectInterviewType = styled.div`
  display: flex;
  padding: 14px 16px;
  align-items: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  font-size: 14px;
  z-index: 100;
`;

export const SelectInterviewTypeMenu = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  font-size: 14px;
  position: absolute;
  top: 0;
  z-index: 10;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;
  // width: 81%;
  justify-content: space-between;
`;

export const JobSelectMenuDiv = styled.div<{ isFilterJobSelect?: boolean }>`
  // margin-bottom: 20px;
  position: ${({ isFilterJobSelect }) => isFilterJobSelect && 'relative'};
`;

export const StyledSelectMenu = styled.select<{
  isModalSelect?: boolean;
  isApplicantStatusSelect?: boolean;
  isFilterJobSelect?: boolean;
  width?: string;
}>`
  -webkit-appearance: none;
  width: ${({ isModalSelect }) => isModalSelect && '280px'};
  width: ${({ isApplicantStatusSelect }) => isApplicantStatusSelect && '200px'};
  width: ${({ isFilterJobSelect }) => isFilterJobSelect && '280px'};
  width: ${({ width }) => width && `${width};`};
  display: flex;
  padding: 14px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid ${colors.primary_500};
  background: ${colors.neutral_50};
  font-family: ${font.family};
  color: ${colors.black};

  a .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const DownArrowContainer = styled.div<{
  isJobSelectionMenu?: boolean;
  isEmployeeFilter?: boolean;
}>`
  position: absolute;
  top: ${({ isJobSelectionMenu }) => (isJobSelectionMenu ? '15px' : '26px')};
  right: ${({ isJobSelectionMenu }) => (isJobSelectionMenu ? '' : '30px')};
  left: ${({ isJobSelectionMenu }) => isJobSelectionMenu && '250px'};
  top: ${({ isEmployeeFilter }) => isEmployeeFilter && '17px'};
  right: ${({ isEmployeeFilter }) => isEmployeeFilter && '17px'};
`;

export const WrapperDiv = styled.div`
  display: flex;
  gap: 8px;
`;

export const EmployeeStyledSelectJobTitleAndImgWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StyledSelect = styled.select`
  color: var(--Primary-800, #8b0000);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid ${colors.primary_500};
  background: ${colors.neutral_50};
  font-family: ${font.family};
  color: ${colors.black};

  // Remove defaults in Safari (ew)
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // Add a custom arrow to make it consistent across browsers
  // use this arrow on other select inputs to stay consistent
  // add it to our aws in the future
  background-image: url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/png/512/ios7-arrow-down.png');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
`;

export const StyledSearchInput = styled.input`
  padding: 14px;
  border-radius: 8px;
  border: 2px solid ${colors.primary_500};
  background: ${colors.neutral_50};
  font-family: ${font.family};
  color: ${colors.black};
  margin-right: 8px;

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const StyledEmployeeStatusAndIconWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  align-items: center;
`;

// export const StyledEmployeesWrapper = styled.div`
//   overflow-x: auto;

//   /* Hide scrollbar for webkit browsers */
//   ::-webkit-scrollbar {
//     display: none;
//   }

//   /* Hide scrollbar for IE, Edge and Firefox */
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// `;

export const StyledEmployeesWrapper = styled.div`
  box-sizing: border-box;
`;

export const SuperAdminHeader = styled.div`
  color: var(--Shadow-00, #010101);
  font-family: Lato;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const StyledCvAndCompanyCvButtonsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const StyledCVButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-style: none;
  background-color: transparent;
  font-family: ${font.family};
  font-size: 16;
  font-weight: 400;
  color: ${colors.neutral_900};
`;

export const StyledEmployeeJobPositionSelect = styled.select`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-style: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
`;

export const ChevronDownIconWrapper = styled.div`
  position: absolute;
  right: 0.75em;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: ${colors.primary_500};
  border-radius: 50%;
`;

export const StyledEmployeedTableScrollWrapper = styled.div`
  box-sizing: border-box;
  overflow-x: auto;
  flex: 1;
  max-width: calc(100vw - 300px);
`;
