import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Company } from './edit-company-interface';
import {
  Button,
  ButtonsContainer,
  Checkbox,
  CheckboxLabel,
  ContentWrapper,
  FieldContainer,
  Form,
  ImageContainer,
  ImagePreview,
  Input,
  Label,
  NavbarAndContentTransparentContainer,
} from './edit-company.styled';
import { apiRoute } from '../../../../constants/api-constants';
import MessagePopup from '../../../messages/message-popup/message-popup';
import { font, Text } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import {
  phoneRegex,
  validPhoneNumberErrorLabelText,
} from '../../../../function-utils/phone-number-validation';

interface ValidationErrors {
  [key: string]: string;
}

interface EditCompanyModalProps {
  editingCompany: Company;
  handleBack: () => void;
}

export const EditCompanyModal: React.FC<EditCompanyModalProps> = ({
  editingCompany,
  handleBack,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState<Company>({ ...editingCompany });
  const [profilePictureFile, setProfilePictureFile] = useState<File | null>(
    null
  );
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  // 1. Add state for confirmPassword
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    //   eslint-disable-next-line
    let newValue: any;

    if (type === 'checkbox') {
      newValue = checked;
    } else if (type === 'number') {
      newValue = Number(value);
    } else {
      newValue = value;
    }

    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }

    // 2. Clear confirmPassword error when password changes
    if (name === 'password' && errors['confirmPassword']) {
      setErrors({
        ...errors,
        confirmPassword: '',
      });
    }
  };

  // 3. Handle confirmPassword change
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setConfirmPassword(value);

    if (errors['confirmPassword']) {
      setErrors({
        ...errors,
        confirmPassword: '',
      });
    }
  };

  const handleProfilePictureChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfilePictureFile(file);
      setFormData({
        ...formData,
        profile_picture: URL.createObjectURL(file),
      });

      if (errors['profile_picture']) {
        setErrors({
          ...errors,
          profile_picture: '',
        });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors: ValidationErrors = validateForm(
      formData,
      profilePictureFile,
      confirmPassword // Pass confirmPassword to validation
    );
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setPopUp({
        text: 'Please correct the highlighted errors before submitting.',
        type: 'error',
      });

      return;
    }

    setIsSubmitting(true);
    setPopUp(null);

    const formDataToSend = new FormData();
    formDataToSend.append('id', formData.id);
    formDataToSend.append('account_type', formData.account_type);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('password', formData.password);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('website', formData.website);
    formDataToSend.append('phone_number', formData.phone_number);
    formDataToSend.append('industry', formData.industry);
    formDataToSend.append('founded', formData.founded || '');
    formDataToSend.append('address', formData.address);
    formDataToSend.append('country', formData.country);
    formDataToSend.append('locations', formData.locations || '');
    formDataToSend.append('tax_number', formData.tax_number);
    formDataToSend.append('employee_count', formData.employee_count.toString());
    formDataToSend.append('specialities', formData.specialities || '');
    formDataToSend.append('is_verified', formData.is_verified.toString());
    formDataToSend.append('is_on_trial', formData.is_on_trial.toString());
    formDataToSend.append(
      'first_time_logged_in',
      formData.first_time_logged_in.toString()
    );
    formDataToSend.append('color_template', formData.color_template || '');
    formDataToSend.append(
      'receive_email_notifications',
      formData.receive_email_notifications.toString()
    );
    formDataToSend.append(
      'receive_in_app_notifications',
      formData.receive_in_app_notifications.toString()
    );
    formDataToSend.append(
      'receive_edit_notifications',
      formData.receive_edit_notifications.toString()
    );
    formDataToSend.append(
      'receive_time_off_notifications',
      formData.receive_time_off_notifications.toString()
    );
    formDataToSend.append(
      'subscription_plan_id',
      formData.subscription_plan_id || ''
    );
    formDataToSend.append(
      'stripe_customer_id',
      formData.stripe_customer_id || ''
    );
    formDataToSend.append(
      'stripe_subscription_id',
      formData.stripe_subscription_id || ''
    );
    formDataToSend.append(
      'last_subscription_payed_at',
      formData.last_subscription_payed_at || ''
    );
    formDataToSend.append('is_blocked', formData.is_blocked.toString());

    if (profilePictureFile) {
      formDataToSend.append('profile_picture', profilePictureFile);
    }

    console.log(editingCompany.id);
    const apiUrl = `${apiRoute}/complete-update-company`;

    console.log('Preparing to send PUT request to:', apiUrl);
    console.log('FormData entries:');
    formDataToSend.forEach((value, key) => {
      console.log(`${key}:`, value);
    });

    try {
      const response = await axios.put(apiUrl, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Company information updated successfully:', response.data);
      setPopUp({
        text: 'Company profile updated successfully!',
        type: 'success',
      });
      //   eslint-disable-next-line
    } catch (error: any) {
      console.error(
        'Error updating company information:',
        error.response?.data?.message || error.message
      );
      setPopUp({
        text:
          error.response?.data?.message ||
          'Failed to update company information.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = (
    data: Company,
    profilePic: File | null,
    confirmPassword: string // Accept confirmPassword as a parameter
  ): ValidationErrors => {
    const errors: ValidationErrors = {};

    if (!data.account_type || data.account_type.trim() === '') {
      errors.account_type = 'Account Type is required.';
    }

    if (!data.email || data.email.trim() === '') {
      errors.email = 'Email is required.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (data.password && data.password.trim() !== '') {
      if (data.password.length < 6) {
        errors.password = 'Password must be at least 6 characters long.';
      }

      if (data.password !== confirmPassword) {
        errors.confirmPassword = 'Passwords do not match.';
      }
    }

    if (!data.name || data.name.trim() === '') {
      errors.name = 'Name is required.';
    }

    if (!data.website || data.website.trim() === '') {
      errors.website = 'Website is required.';
    } else if (
      !/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(
        data.website
      )
    ) {
      errors.website = 'Please enter a valid website URL.';
    }

    if (data.phone_number && !phoneRegex.test(data.phone_number)) {
      errors.phone_number = validPhoneNumberErrorLabelText;
    }

    if (!data.industry || data.industry.trim() === '') {
      errors.industry = 'Industry is required.';
    }

    if (!data.address || data.address.trim() === '') {
      errors.address = 'Address is required.';
    }

    if (!data.country || data.country.trim() === '') {
      errors.country = 'Country is required.';
    }

    if (!data.tax_number || data.tax_number.trim() === '') {
      errors.tax_number = 'Tax number is required.';
    }

    if (data.employee_count === undefined || data.employee_count === null) {
      errors.employee_count = 'Employee count is required.';
    } else if (parseInt(data.employee_count) < 0) {
      errors.employee_count = 'Employee count cannot be negative.';
    }

    if (profilePic) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(profilePic.type)) {
        errors.profile_picture = 'Only JPG, PNG, and GIF files are allowed.';
      }
      const maxSizeInBytes = 5 * 1024 * 1024;
      if (profilePic.size > maxSizeInBytes) {
        errors.profile_picture = 'File size should not exceed 5MB.';
      }
    }

    return errors;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  console.log(formData);

  return (
    <ContentWrapper>
      <NavbarAndContentTransparentContainer>
        <Form onSubmit={handleSubmit} noValidate>
          <h2>Edit Company Information</h2>

          {/* Profile Picture */}
          <FieldContainer>
            <Label htmlFor="profile_picture">Profile Picture</Label>
            <ImageContainer>
              <ImagePreview
                src={
                  profilePictureFile
                    ? URL.createObjectURL(profilePictureFile)
                    : typeof formData.profile_picture === 'string'
                    ? formData.profile_picture
                    : ''
                }
                alt="Profile"
                onClick={handleImageClick}
                style={{ cursor: 'pointer' }}
              />
              <Input
                type="file"
                id="profile_picture"
                name="profile_picture"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleProfilePictureChange}
              />
            </ImageContainer>
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={10}
              fontWeight={700}
              color={colors.primary_500}
            >
              Click on the image to select a new one
            </Text>
            {errors.profile_picture && (
              <Text color="red" fontSize={12}>
                {errors.profile_picture}
              </Text>
            )}
          </FieldContainer>

          {/* Account Type */}
          <FieldContainer>
            <Label htmlFor="account_type">Account Type</Label>
            <Input
              type="text"
              id="account_type"
              name="account_type"
              value={formData.account_type || ''}
              onChange={handleInputChange}
            />
            {errors.account_type && (
              <Text color="red" fontSize={12}>
                {errors.account_type}
              </Text>
            )}
          </FieldContainer>

          {/* Email */}
          <FieldContainer>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email || ''}
              onChange={handleInputChange}
            />
            {errors.email && (
              <Text color="red" fontSize={12}>
                {errors.email}
              </Text>
            )}
          </FieldContainer>

          {/* Password */}
          <FieldContainer>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              id="password"
              name="password"
              value={formData.password || ''}
              onChange={handleInputChange}
            />
            {errors.password && (
              <Text color="red" fontSize={12}>
                {errors.password}
              </Text>
            )}
          </FieldContainer>

          {/* Confirm Password */}
          <FieldContainer>
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {errors.confirmPassword && (
              <Text color="red" fontSize={12}>
                {errors.confirmPassword}
              </Text>
            )}
          </FieldContainer>

          {/* Name */}
          <FieldContainer>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name || ''}
              onChange={handleInputChange}
            />
            {errors.name && (
              <Text color="red" fontSize={12}>
                {errors.name}
              </Text>
            )}
          </FieldContainer>

          {/* Website */}
          <FieldContainer>
            <Label htmlFor="website">Website</Label>
            <Input
              type="url"
              id="website"
              name="website"
              value={formData.website || ''}
              onChange={handleInputChange}
            />
            {errors.website && (
              <Text color="red" fontSize={12}>
                {errors.website}
              </Text>
            )}
          </FieldContainer>

          {/* Phone Number */}
          <FieldContainer>
            <Label htmlFor="phone_number">Phone Number</Label>
            <Input
              type="tel"
              id="phone_number"
              name="phone_number"
              value={formData.phone_number || ''}
              onChange={handleInputChange}
            />
            {errors.phone_number && (
              <Text color="red" fontSize={12}>
                {errors.phone_number}
              </Text>
            )}
          </FieldContainer>

          {/* Industry */}
          <FieldContainer>
            <Label htmlFor="industry">Industry</Label>
            <Input
              type="text"
              id="industry"
              name="industry"
              value={formData.industry || ''}
              onChange={handleInputChange}
            />
            {errors.industry && (
              <Text color="red" fontSize={12}>
                {errors.industry}
              </Text>
            )}
          </FieldContainer>

          {/* Founded */}
          <FieldContainer>
            <Label htmlFor="founded">Founded</Label>
            <Input
              type="date"
              id="founded"
              name="founded"
              value={formData.founded || ''}
              onChange={handleInputChange}
            />
            {errors.founded && (
              <Text color="red" fontSize={12}>
                {errors.founded}
              </Text>
            )}
          </FieldContainer>

          {/* Address */}
          <FieldContainer>
            <Label htmlFor="address">Address</Label>
            <Input
              type="text"
              id="address"
              name="address"
              value={formData.address || ''}
              onChange={handleInputChange}
            />
            {errors.address && (
              <Text color="red" fontSize={12}>
                {errors.address}
              </Text>
            )}
          </FieldContainer>

          {/* Country */}
          <FieldContainer>
            <Label htmlFor="country">Country</Label>
            <Input
              type="text"
              id="country"
              name="country"
              value={formData.country || ''}
              onChange={handleInputChange}
            />
            {errors.country && (
              <Text color="red" fontSize={12}>
                {errors.country}
              </Text>
            )}
          </FieldContainer>

          {/* Locations */}
          <FieldContainer>
            <Label htmlFor="locations">Locations</Label>
            <Input
              type="text"
              id="locations"
              name="locations"
              value={formData.locations || ''}
              onChange={handleInputChange}
            />
            {errors.locations && (
              <Text color="red" fontSize={12}>
                {errors.locations}
              </Text>
            )}
          </FieldContainer>

          {/* Tax Number */}
          <FieldContainer>
            <Label htmlFor="tax_number">Tax Number</Label>
            <Input
              type="text"
              id="tax_number"
              name="tax_number"
              value={formData.tax_number || ''}
              onChange={handleInputChange}
            />
            {errors.tax_number && (
              <Text color="red" fontSize={12}>
                {errors.tax_number}
              </Text>
            )}
          </FieldContainer>

          {/* Employee Count */}
          <FieldContainer>
            <Label htmlFor="employee_count">Employee Count</Label>
            <Input
              type="text"
              id="employee_count"
              name="employee_count"
              value={formData.employee_count}
              onChange={handleInputChange}
            />
            {errors.employee_count && (
              <Text color="red" fontSize={12}>
                {errors.employee_count}
              </Text>
            )}
          </FieldContainer>

          {/* Specialities */}
          <FieldContainer>
            <Label htmlFor="specialities">Specialities</Label>
            <Input
              type="text"
              id="specialities"
              name="specialities"
              value={formData.specialities || ''}
              onChange={handleInputChange}
            />
            {errors.specialities && (
              <Text color="red" fontSize={12}>
                {errors.specialities}
              </Text>
            )}
          </FieldContainer>

          {/* Boolean Fields */}
          {/* Is Verified */}
          <FieldContainer>
            <CheckboxLabel htmlFor="is_verified">
              <Checkbox
                type="checkbox"
                id="is_verified"
                name="is_verified"
                checked={formData.is_verified}
                onChange={handleInputChange}
              />
              Is Verified
            </CheckboxLabel>
          </FieldContainer>

          {/* Is On Trial */}
          <FieldContainer>
            <CheckboxLabel htmlFor="is_on_trial">
              <Checkbox
                type="checkbox"
                id="is_on_trial"
                name="is_on_trial"
                checked={formData.is_on_trial}
                onChange={handleInputChange}
              />
              Is On Trial
            </CheckboxLabel>
          </FieldContainer>

          {/* First Time Logged In */}
          <FieldContainer>
            <CheckboxLabel htmlFor="first_time_logged_in">
              <Checkbox
                type="checkbox"
                id="first_time_logged_in"
                name="first_time_logged_in"
                checked={formData.first_time_logged_in}
                onChange={handleInputChange}
              />
              First Time Logged In
            </CheckboxLabel>
          </FieldContainer>

          {/* Color Template */}
          <FieldContainer>
            <Label htmlFor="color_template">Color Template</Label>
            <Input
              type="text"
              id="color_template"
              name="color_template"
              value={formData.color_template || ''}
              onChange={handleInputChange}
            />
            {errors.color_template && (
              <Text color="red" fontSize={12}>
                {errors.color_template}
              </Text>
            )}
          </FieldContainer>

          {/* Receive Email Notifications */}
          <FieldContainer>
            <CheckboxLabel htmlFor="receive_email_notifications">
              <Checkbox
                type="checkbox"
                id="receive_email_notifications"
                name="receive_email_notifications"
                checked={formData.receive_email_notifications}
                onChange={handleInputChange}
              />
              Receive Email Notifications
            </CheckboxLabel>
          </FieldContainer>

          {/* Receive In-App Notifications */}
          <FieldContainer>
            <CheckboxLabel htmlFor="receive_in_app_notifications">
              <Checkbox
                type="checkbox"
                id="receive_in_app_notifications"
                name="receive_in_app_notifications"
                checked={formData.receive_in_app_notifications}
                onChange={handleInputChange}
              />
              Receive In-App Notifications
            </CheckboxLabel>
          </FieldContainer>

          {/* Receive Edit Notifications */}
          <FieldContainer>
            <CheckboxLabel htmlFor="receive_edit_notifications">
              <Checkbox
                type="checkbox"
                id="receive_edit_notifications"
                name="receive_edit_notifications"
                checked={formData.receive_edit_notifications}
                onChange={handleInputChange}
              />
              Receive Edit Notifications
            </CheckboxLabel>
          </FieldContainer>

          {/* Receive Time Off Notifications */}
          <FieldContainer>
            <CheckboxLabel htmlFor="receive_time_off_notifications">
              <Checkbox
                type="checkbox"
                id="receive_time_off_notifications"
                name="receive_time_off_notifications"
                checked={formData.receive_time_off_notifications}
                onChange={handleInputChange}
              />
              Receive Time Off Notifications
            </CheckboxLabel>
          </FieldContainer>

          {/* Subscription Plan ID */}
          <FieldContainer>
            <Label htmlFor="subscription_plan_id">Subscription Plan ID</Label>
            <Input
              type="number"
              id="subscription_plan_id"
              name="subscription_plan_id"
              value={formData.subscription_plan_id || ''}
              onChange={handleInputChange}
            />
            {errors.subscription_plan_id && (
              <Text color="red" fontSize={12}>
                {errors.subscription_plan_id}
              </Text>
            )}
          </FieldContainer>

          {/* Stripe Customer ID */}
          <FieldContainer>
            <Label htmlFor="stripe_customer_id">Stripe Customer ID</Label>
            <Input
              type="text"
              id="stripe_customer_id"
              name="stripe_customer_id"
              value={formData.stripe_customer_id || ''}
              onChange={handleInputChange}
            />
            {errors.stripe_customer_id && (
              <Text color="red" fontSize={12}>
                {errors.stripe_customer_id}
              </Text>
            )}
          </FieldContainer>

          {/* Stripe Subscription ID */}
          <FieldContainer>
            <Label htmlFor="stripe_subscription_id">
              Stripe Subscription ID
            </Label>
            <Input
              type="text"
              id="stripe_subscription_id"
              name="stripe_subscription_id"
              value={formData.stripe_subscription_id || ''}
              onChange={handleInputChange}
            />
            {errors.stripe_subscription_id && (
              <Text color="red" fontSize={12}>
                {errors.stripe_subscription_id}
              </Text>
            )}
          </FieldContainer>

          {/* Last Subscription Paid At */}
          <FieldContainer>
            <Label htmlFor="last_subscription_payed_at">
              Last Subscription Paid At
            </Label>
            <Input
              type="date"
              id="last_subscription_payed_at"
              name="last_subscription_payed_at"
              value={formData.last_subscription_payed_at || ''}
              onChange={handleInputChange}
            />
            {errors.last_subscription_payed_at && (
              <Text color="red" fontSize={12}>
                {errors.last_subscription_payed_at}
              </Text>
            )}
          </FieldContainer>

          {/* Is Blocked */}
          <FieldContainer>
            <CheckboxLabel htmlFor="is_blocked">
              <Checkbox
                type="checkbox"
                id="is_blocked"
                name="is_blocked"
                checked={formData.is_blocked}
                onChange={handleInputChange}
              />
              Is Blocked
            </CheckboxLabel>
          </FieldContainer>

          {/* Buttons */}
          <ButtonsContainer>
            <Button onClick={() => handleBack()} isBack={true} type="button">
              Back
            </Button>
            <Button isBack={false} type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </ButtonsContainer>

          {/* Success or Error Popup */}
          {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
        </Form>
      </NavbarAndContentTransparentContainer>
    </ContentWrapper>
  );
};
