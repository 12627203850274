import styled from 'styled-components';
import { colors } from '../../../../../../style-utils/colors';
import { font } from '../../../../../../style-utils/text';

export const SearchCandidatePositionAndButtonWrapper = styled.div`
  display: flex;
  width: 80%;
  align-self: center;
  margin: 20px 0;
`;

export const SearchCandidatesAndPositionWrapper = styled.div`
  display: flex;
  width: 80%;
`;

export const CompareButton = styled.button<{ disabled: boolean }>`
  width: 20%;
  background-color: ${({ disabled }) =>
    disabled ? colors.neutral_300 : colors.secondary_700};
  color: white;
  border: none;
  padding: 12px 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-family: inherit;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? colors.neutral_300 : colors.secondary_800};
  }
`;

export const SearchCandidatesAndSearchIconWrapper = styled.div`
  position: relative;
  width: 50%;
  background: ${colors.secondary_50};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  box-sizing: border-box;
  cursor: text;
  margin-right: 8px;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  padding: 12px 16px 12px 0;
  font-family: ${font.family};
  font-size: 16px;
  color: ${colors.neutral_800};
  outline: none;

  &::placeholder {
    color: ${colors.neutral_400};
  }
`;

export const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid ${colors.neutral_200};
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;
  padding: 0;
  list-style: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

interface DropdownItemProps {
  selected: boolean;
}

export const DropdownItem = styled.li<DropdownItemProps>`
  padding: 10px 16px;
  cursor: pointer;
  font-family: ${font.family};
  font-size: 16px;
  color: ${colors.neutral_800};
  transition: background-color 0.2s ease;
  background-color: ${({ selected }) =>
    selected ? colors.secondary_50 : 'transparent'};

  &:hover {
    background-color: ${colors.neutral_100};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// New Styled Checkbox Component Styled as Radio Button
export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 13px;
  height: 13px;
  border: 2px solid ${colors.neutral_800};
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  cursor: pointer;

  &:checked::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 7px;
    height: 7px;
    background-color: ${colors.secondary_600};
    border-radius: 50%;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: ${colors.neutral_400};
  }
`;

export const StyledSearchIcon = styled.div`
  position: absolute;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeselectButton = styled.button`
  background: transparent;
  border: none;
  color: ${colors.neutral_600};
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  &:hover {
    color: ${colors.neutral_800};
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.secondary_100};
  border-radius: 2px;
  padding: 2px 5px;
  margin: 2px;
`;

export const TagLabel = styled.span`
  margin-right: 5px;
  font-size: 0.9em;
`;

export const TagRemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  line-height: 1;
  padding: 0;
  color: #555;

  &:hover {
    color: #000;
  }
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
`;
