import React from 'react';
import { ToggleContainer, ToggleOption } from './toggle.styled';

interface ToggleProps {
  isMonthly: boolean;
  handleToggle: (toggleState: boolean) => void;
}

export const Toggle: React.FC<ToggleProps> = ({ isMonthly, handleToggle }) => {
  return (
    <ToggleContainer>
      <ToggleOption active={isMonthly} onClick={() => handleToggle(true)}>
        Monthly
      </ToggleOption>
      <ToggleOption active={!isMonthly} onClick={() => handleToggle(false)}>
        Yearly
        {/* <span style={{ fontSize: '12px' }}>(Save 15%)</span> */}
      </ToggleOption>
    </ToggleContainer>
  );
};
