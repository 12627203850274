import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const Wrapper = styled.div<{
  isSignUp: boolean;
  padding?: string;
}>`
  background: ${colors.white};
  border-radius: 16px;
  padding: ${({ isSignUp, padding }) => (isSignUp ? padding || '40px' : '0px')};
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 0;
  padding-bottom: 16px;
  font-size: 24px;
  font-family: ${font.family};
  color: ${colors.primary_500};
`;

export const SubTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: ${font.family};
  color: ${colors.base_black};
  font-weight: 500;
`;

export const SubscriptionPlansAndToggleButtonsWrapper = styled.div<{
  isSignUp: boolean;
}>`
  display: flex;
  justify-content: ${({ isSignUp }) => (isSignUp ? 'center' : 'space-between')};
  align-items: center;
  padding-top: 16px;
`;

export const SubscriptionPlansTextWrapper = styled.div`
  padding-left: 4px;
  font-family: ${font.family};
  font-weight: 700;
  font-size: 16px;
`;

export const Button = styled.button<{ disabled?: boolean }>`
  background-color: ${({ disabled }) =>
    !disabled ? `${colors.secondary_500}` : `${colors.secondary_200}`};
  color: ${colors.white};
  font-family: ${font.family};
  font-weight: 700;
  font-size: 16px;
  width: 115px;
  height: 35px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none; /* Remove default border */
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
  flex-direction: row-reverse;
`;

export const ToggleButtonsWrapper = styled.div`
  padding-right: 4px;
`;
