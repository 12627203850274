import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../function-utils/log-out';
import { SignUpData, useAuthForms } from '../../hooks/use-auth-forms';
import { loadSubscriptionPlansNew } from '../../../function-utils/subscription-plan-utils';
import { SubscriptionPlanNew } from '../super-admin/subscription-plans/interface';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { loadStripe } from '@stripe/stripe-js';
import {
  LogInWrapper,
  LogInFormContainer,
  LogInFormWrapper,
} from '../../login/login.styled';
import {
  SubTitle,
  Title,
  Wrapper,
  SubscriptionPlansAndToggleButtonsWrapper,
  SubscriptionPlansTextWrapper,
  Button,
  ButtonsWrapper,
} from './change-plan.styled';
import { Xicon } from '../../icons/x-skill';
import PaymentPlansTableNew from './payment-plans-table-new/payment-plans-table-new';
import { FullScreenSpinner } from '../../spinner-new/spinner-new';

interface ChangePlanPageProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  refetchUser?: () => Promise<void>;
  setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData?: SignUpData;
  handlePlanSelection?: (plan: string) => void;
}

export const ChangePlanPage: React.FC<ChangePlanPageProps> = ({
  user,
  setUser,
  refetchUser,
  handlePlanSelection,
}) => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState<SubscriptionPlanNew[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [selectedPlanAmount, setSelectedPlanAmount] = useState<number | null>(
    null
  );

  const { signUpData, setSignUpData } = useAuthForms();

  // const handleLogoClick = () => {
  //   if (navigate) navigate('/');
  // };

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plans = await loadSubscriptionPlansNew();
        if (isMounted) {
          setPlans(plans);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching subscription plans:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const fetchUser = async () => {
      setUser(null);
      if (refetchUser) await refetchUser();
    };

    fetchPlans();

    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const setSelectedPlan = (planId: string, amount: number) => {
    setSelectedPlanId(planId);
    setSelectedPlanAmount(amount);
    handlePlanSelection && handlePlanSelection(planId);
    console.log(`Selected Plan ID: ${planId}, Amount: ${amount}`);
  };

  const handleUpgrade = async () => {
    console.log('handleUpgrade invoked.');

    if (selectedPlanId && selectedPlanAmount) {
      console.log(
        `Selected Plan ID: ${selectedPlanId}, Amount: ${selectedPlanAmount}`
      );
      console.log(`User Email: ${user?.email}`);

      setLoading(true);

      try {
        console.log('Sending request to create checkout session.');
        const response = await axios.post(
          `${apiRoute}/create-checkout-session`,
          {
            planId: selectedPlanId,
            customerEmail: user?.email,
          }
        );

        console.log(
          'Received response from create-checkout-session:',
          response.data
        );

        const { checkoutSessionId } = response.data;

        if (!checkoutSessionId) {
          console.error('No checkoutSessionId received from the server.');
          console.log(
            'No checkoutSessionId received from the server.',
            'handleUpgrade'
          );
          setLoading(false);

          return;
        }

        console.log(`Checkout Session ID: ${checkoutSessionId}`);

        const stripePublishableKey =
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
        console.log(
          `Loading Stripe with publishable key: ${stripePublishableKey}`
        );

        const stripe = await loadStripe(stripePublishableKey);

        if (!stripe) {
          console.error('Stripe failed to initialize.');
          console.log('Stripe failed to initialize.', 'handleUpgrade');
          setLoading(false);

          return;
        }

        console.log('Stripe initialized successfully.');

        console.log('Redirecting to Stripe Checkout.');
        const stripeResult = await stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        });

        console.log('Stripe redirectToCheckout result:', stripeResult);

        if (stripeResult.error) {
          console.error(
            'Error redirecting to Stripe checkout:',
            stripeResult.error.message
          );
          console.log(
            `Error redirecting to Stripe checkout: ${stripeResult.error.message}`,
            'handleUpgrade'
          );
        } else {
          console.log(
            'Redirected to Stripe Checkout successfully. Navigating to /payment-success.'
          );
          navigate('/payment-success');
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.error(
          'Error during plan upgrade:',
          error.response ? error.response.data : error.message
        );
        console.log(
          `Error during plan upgrade: ${
            error.response ? JSON.stringify(error.response.data) : error.message
          }`,
          'handleUpgrade'
        );
      } finally {
        console.log(
          'handleUpgrade process completed. Setting loading to false.'
        );
        setLoading(false);
        await handleLogout();
      }
    } else {
      console.error('Please select a plan before upgrading.');
      console.log(
        'Upgrade attempted without selecting a plan.',
        'handleUpgrade'
      );
    }
  };

  const handleLogout = async () => {
    if (setUser) {
      await logOut(setUser, navigate);
    }
  };

  const defaultSelectedPlanId = user?.subscription_plan_id;

  if (loading) {
    return <FullScreenSpinner />;
  }

  return (
    <LogInWrapper id="log-in-form" isFromChangePlan>
      {/* <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
        />
        <CompanyTitle>WorWise Client Board</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome back! Please enter your details to start optimizing your
          team’s hiring process.
        </Description>
      </TextContainer> */}
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer maxWidth="1440px" overflow="none">
          <Wrapper padding="40px" id="wrapper" isSignUp={true} key={user?.id}>
            <Title>
              <Xicon />
              Your Trial Has Expired
            </Title>
            <SubTitle>
              To continue using our services, please upgrade your plan.
            </SubTitle>
            <SubscriptionPlansAndToggleButtonsWrapper isSignUp={false}>
              <SubscriptionPlansTextWrapper>
                Subscription Plans
              </SubscriptionPlansTextWrapper>
            </SubscriptionPlansAndToggleButtonsWrapper>
            <PaymentPlansTableNew
              user={user}
              isSignUp={true}
              setSignUpData={setSignUpData}
              signUpData={signUpData}
              plans={plans}
              setSelectedPlan={setSelectedPlan}
              defaultSelectedPlanId={defaultSelectedPlanId || null}
            />
            {/* <PaymentPlansTable
              user={user}
              isSignUp={false}
              setSignUpData={setSignUpData}
              signUpData={signUpData}
              plans={plans}
              setSelectedPlan={setSelectedPlan}
              defaultSelectedPlanId={defaultSelectedPlanId || null}
              isMonthly={isMonthly}
              selectedPlanId={selectedPlanId}
            /> */}
            <ButtonsWrapper>
              <Button disabled={false} onClick={handleLogout}>
                Logout
              </Button>
              <Button
                onClick={handleUpgrade}
                disabled={loading || !selectedPlanId}
              >
                {loading ? 'Loading' : 'Upgrade Plan'}
              </Button>
            </ButtonsWrapper>
          </Wrapper>
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};
