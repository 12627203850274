import axios from 'axios';
import {
  SubscriptionPlan,
  SubscriptionPlanNew,
} from '../components/pages/super-admin/subscription-plans/interface';
import { apiRoute } from '../constants/api-constants';

export const loadSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
  try {
    const response = await axios.get(`${apiRoute}/subscription-plan/all`);
    console.log('Subscription plans response:', response.data);

    return response.data;
  } catch (err) {
    console.error('Error fetching subscription plans:', err);
    throw new Error('Error fetching subscription plans');
  }
};

export const loadSubscriptionPlansNew = async (): Promise<
  SubscriptionPlanNew[]
> => {
  try {
    const response = await axios.get(`${apiRoute}/subscription-plan-new/all`);
    console.log('Subscription plans response:', response.data);

    return response.data;
  } catch (err) {
    console.error('Error fetching subscription plans:', err);
    throw new Error('Error fetching subscription plans');
  }
};
