import styled from 'styled-components';
import { font } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';

export const ComparisonModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.h1`
  margin: 0;
  font-family: ${font.family};
  font-size: 28px;
`;

export const TitleAndIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SearchCandidateTitle = styled.h2`
  margin: 0;
  font-family: ${font.family};
  font-size: 24px;
  text-align: center;
  padding-top: 16px;
`;

export const ComparisonDescription = styled.h2`
  margin: 0;
  font-family: ${font.family};
  font-size: 14px;
  text-align: center;
  padding-top: 8px;
  max-width: 420px;
  font-weight: 400;
  display: flex;
  align-self: center;
`;

export const XButton = styled.div`
  font-weight: 700;
  cursor: pointer;
`;

export const TableWrapper = styled.div`
  background: ${colors.neutral_50};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TableTitle = styled.h1`
  margin: 0;
  font-family: ${font.family};
  font-size: 24px;
  text-align: center;
  padding-top: 16px;
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 16px;
  padding-right: 32px;
`;

export const SaveButton = styled.div`
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  color: white;
  background: ${colors.primary_500};
  cursor: pointer;
`;
