import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  PricingPackages,
  Content,
  PricingHeading,
  PricingInfo,
  Table,
  TableHeadings,
  TableData,
  TableData1,
  TableBody,
  PricingDownPart,
  PlanButton,
  Paragraph,
} from './pricing.styled';
import checkImg from '../../lp-images/check.png';
import xImg from '../../lp-images/close.png';
import { LandingPageProps } from '../../landing-page';
import { SubscriptionPlanNew } from '../../../components/pages/super-admin/subscription-plans/interface';
import { loadSubscriptionPlansNew } from '../../../function-utils/subscription-plan-utils';
import { RotatingSpinner } from '../../../components/spinner-new/spinner-new';

const renderFeatureContent = (feature: {
  name?: string;
  value: string | null;
}) => {
  if (!feature.name || feature.value === null) {
    return <img src={xImg} alt="X" />;
  }
  if (feature.value === '') {
    return <img src={checkImg} alt="Check" />;
  }

  return <span>{feature.value}</span>;
};

export const PricingList: React.FC<LandingPageProps> = React.forwardRef<
  HTMLDivElement,
  LandingPageProps
>((props, ref) => {
  const [plans, setPlans] = useState<SubscriptionPlanNew[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [features, setFeatures] = useState<
    {
      name: string;
      value: string | null;
    }[]
  >([]);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const fetchedPlans = await loadSubscriptionPlansNew();

        const activePlans = fetchedPlans
          .filter((plan) => plan.status === 'active')
          .sort(
            (a, b) => parseFloat(a.price.amount) - parseFloat(b.price.amount)
          );

        setPlans(activePlans);

        // Deduplicate features by 'name'
        const featureMap = new Map();
        activePlans.forEach((plan) => {
          plan.features.forEach((feature) => {
            if (!featureMap.has(feature.name)) {
              featureMap.set(feature.name, feature);
            }
          });
        });

        setFeatures(Array.from(featureMap.values()));
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  if (loading) {
    return <RotatingSpinner />;
  }

  return (
    <PricingPackages id="pricing" ref={ref}>
      <Content>
        <PricingHeading>
          Choose pricing plan which suits your needs
        </PricingHeading>
        <PricingInfo>
          Choose a plan that fits your budget and meets your team’s needs.
        </PricingInfo>
      </Content>

      <Table>
        <thead>
          <tr>
            <TableHeadings>FEATURES</TableHeadings>
            {plans.map((plan) => (
              <TableHeadings key={plan.id}>
                {plan.price.amount}/{plan.price.currency} {plan.title}
              </TableHeadings>
            ))}
          </tr>
        </thead>
        <TableBody>
          {features.map((feature, i) => (
            <tr key={i}>
              <TableData1>{feature.name}</TableData1>
              {plans.map((plan) => {
                const planFeature = plan.features.find(
                  (f) => f.name === feature.name
                );

                return (
                  <TableData key={plan.id}>
                    {renderFeatureContent(
                      planFeature ?? { name: '', value: null }
                    )}
                  </TableData>
                );
              })}
            </tr>
          ))}
        </TableBody>
      </Table>

      <PricingDownPart>
        <Link to="/register-company" target="blank">
          <PlanButton>Choose Your Plan</PlanButton>
        </Link>
        <Paragraph>
          Or Contact us for a custom plan. No hidden charges!
        </Paragraph>
      </PricingDownPart>
    </PricingPackages>
  );
});

PricingList.displayName = 'PricingList';
