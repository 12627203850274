import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  border-radius: 16px;
  background: var(--Creme-100, #f5f4f0);
  position: relative;
  z-index: 1001;
  display: flex;
  width: 670px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-height: 90%;
  overflow: auto;

  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* Optional: Hide scrollbar in Firefox */
  scrollbar-width: none;

  /* Optional: Hide scrollbar in IE/Edge */
  -ms-overflow-style: none;
`;

export const StyledTitle = styled.div`
  color: #000;
  font-family: Lato;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const TitleAndInputWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: white;
  border-radius: 8px;
`;

export const StyledLabel = styled.label`
  color: var(--Shadow-00, #010101);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 16px;
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: white;
  border-radius: 8px;
`;

export const StyledInput = styled.input`
  display: flex;
  padding: 12px 12px 12px 18px;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Neutral-50, #f3f2f2);
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f3f2f2;
  font-size: 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledTextAreaInput = styled.textarea`
  display: flex;
  padding: 12px 12px 12px 18px;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Neutral-50, #f3f2f2);
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f3f2f2;
  font-size: 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 150px;
  resize: none;
`;

export const StyledDateInput = styled.input`
  display: flex;
  padding: 12px 12px 12px 18px;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Neutral-50, #f3f2f2);
  box-sizing: border-box;
  border: 1px solid #f3f2f2;
  width: 100%;
  font-size: 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FeatureInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const FeatureValueAndBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;

export const AddButton = styled.button`
  padding: 4px 12px;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: var(--rounded-1, 4px);
  background: var(--Secondary-400, #5789c2);
`;

export const FeatureItem = styled.div`
  box-sizing: border-box;
  margin-top: 8px;
  display: flex;
  height: 42px;
  padding: 0px 16px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background: var(--Secondary-50, #e4eaf5);
  width: 100%;
  position: relative;
  font-size: 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const EditDeleteIcons = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 10px;
`;

export const DiscountWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledSelect = styled.select`
  display: flex;
  padding: 12px 12px 12px 18px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--Neutral-50, #f3f2f2);
  box-sizing: border-box;
  border: 1px solid #f3f2f2;
  // Remove defaults in Safari (ew)
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 90px;
  // Add a custom arrow to make it consistent across browsers
  // use this arrow on other select inputs to stay consistent
  // add it to our aws in the future
  background-image: url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/png/512/ios7-arrow-down.png');
  background-repeat: no-repeat;
  background-position: right 0px center;
  background-size: 16px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  width: 33%;
  gap: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
`;

export const BackButton = styled.button`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Primary-100, #ffc2b5);
  border: none;
  color: var(--Shadow-00, #010101);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const CreateButton = styled.button`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Primary-500, #e94e3c);
  border: none;
  color: var(--Neutral-50, #f6f7f7);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
