import styled from 'styled-components';
import { font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';

export const ToggleContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.secondary_50};
  border-radius: 25px;
  padding: 4px;
  width: fit-content;
`;

export const ToggleOption = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  box-sizing: border-box;
  font-family: ${font.family};
  font-size: 16px;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: ${(props) => (props.active ? colors.base_white : colors.base_black)};
  background-color: ${(props) =>
    props.active ? colors.secondary_500 : 'transparent'};
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
`;
