import React, { useEffect, useState } from 'react';
import {
  EmployeeDetailsWrapper,
  EmployeeDetailHeader,
  EmployeeDetailDocumentTable,
  EmployeeDetailDocumentTableHeaders,
  EmployeeDetailDocumentTableHeader,
  EmployeeTileOffApplicationButton,
  StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper,
  StyledEmployeeTimeOffRemainingDaysWrapper,
} from './employee.styled';
import { colors } from '../../../style-utils/colors';
import { Text, font } from '../../../style-utils/text';
import { PlusIcon } from '../../icons/plus-icon-small';
import { TableStatusColorCircleIcon } from '../../icons/table-status-color-circle-icon';
import AddEmployeeTimeoffRequest from './add-employee-timeoff-request';
import { NewEmployeeDataTypes } from './add-employee';
import MessagePopup from '../../messages/message-popup/message-popup';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { WhiteCircleIcon } from '../../icons/white-circle-ixon';

interface Props {
  employee: NewEmployeeDataTypes;
  employeeFromDB: () => void;
}

// type ApplicationStatus = 'Pending' | 'Confirmed' | 'Denied';

interface Application {
  application_date: string;
  start_date: string;
  end_date: string;
  duration: string;
  remaining_days: number;
  status: string;
}

const EmployeeDayOffTab: React.FC<Props> = ({ employee, employeeFromDB }) => {
  const [timeOffApplicationVisible, setTimeOffApplicationVisible] =
    useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);

  const handleClose = (success: boolean) => {
    employeeFromDB();
    setTimeOffApplicationVisible(false);
    setPopUpVisible(success);
  };

  const closePopUp = () => {
    setTimeOffApplicationVisible(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (popUpVisible) {
      timer = setTimeout(() => {
        setPopUpVisible(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [popUpVisible]);

  useEffect(() => {
    fetchApplicationsData();
  }, [employee.id, popUpVisible]);

  const fetchApplicationsData = async () => {
    try {
      const response = await axios.get(
        `${apiRoute}/timeoff-application/${employee.id}`
      );
      console.log('response day offs', response.data);
      setApplications(response.data);

      return response.data;
    } catch (error) {
      console.error('Error fetching employee applications:', error);
      throw error;
    }
  };

  return (
    <EmployeeDetailsWrapper>
      {applications.length === 0 && (
        <EmployeeDetailHeader>
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_black}
            fontSize={24}
            fontWeight={700}
            lineHeight="28.8px"
          >
            No Applications
          </Text>
          <StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
            <StyledEmployeeTimeOffRemainingDaysWrapper>
              <WhiteCircleIcon />
              {employee.remaining_days} Days Remaining
            </StyledEmployeeTimeOffRemainingDaysWrapper>
            <EmployeeTileOffApplicationButton
              onClick={() => setTimeOffApplicationVisible(true)}
            >
              <PlusIcon />
              Application
            </EmployeeTileOffApplicationButton>
          </StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
        </EmployeeDetailHeader>
      )}
      {applications.length > 0 && (
        <>
          <EmployeeDetailHeader mb="24px">
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={24}
              fontWeight={700}
              lineHeight="28.8px"
            >
              History
            </Text>
            <StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
              <StyledEmployeeTimeOffRemainingDaysWrapper>
                <WhiteCircleIcon />
                {employee.remaining_days} Days Remaining
              </StyledEmployeeTimeOffRemainingDaysWrapper>
              <EmployeeTileOffApplicationButton
                onClick={() => setTimeOffApplicationVisible(true)}
              >
                <PlusIcon />
                Application
              </EmployeeTileOffApplicationButton>
            </StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
          </EmployeeDetailHeader>
          <EmployeeDetailDocumentTable>
            <EmployeeDetailDocumentTableHeaders>
              <EmployeeDetailDocumentTableHeader
                bgColor={colors.primary_300}
                padding="8px 16px"
                width="120px"
              >
                <Text
                  fontFamily={font.family}
                  margin="0"
                  color={colors.base_white}
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="19.2px"
                  align="center"
                >
                  Date of Application
                </Text>
              </EmployeeDetailDocumentTableHeader>
              {['Start Date', 'End Date', 'Duration', 'Status'].map(
                (header) => (
                  <EmployeeDetailDocumentTableHeader
                    key={header}
                    bgColor={colors.primary_500}
                    padding="8px 16px"
                    fillWidth
                  >
                    <Text
                      fontFamily={font.family}
                      margin="0"
                      color={colors.base_white}
                      fontSize={16}
                      fontWeight={700}
                      lineHeight="19.2px"
                      align="center"
                    >
                      {header}
                    </Text>
                  </EmployeeDetailDocumentTableHeader>
                )
              )}
            </EmployeeDetailDocumentTableHeaders>
            {applications.map((application, index) => (
              <EmployeeDetailDocumentTableHeaders key={index}>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.neutral_50}
                  padding="8px 16px"
                  width="120px"
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_black}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="16.8px"
                    align="center"
                  >
                    {application.application_date}
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.neutral_50}
                  padding="8px 16px"
                  fillWidth
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_black}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="16.8px"
                    align="center"
                  >
                    {application.start_date}
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.neutral_50}
                  padding="8px 16px"
                  fillWidth
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_black}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="16.8px"
                    align="center"
                  >
                    {application.end_date}
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.neutral_50}
                  padding="8px 16px"
                  fillWidth
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_black}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="16.8px"
                    align="center"
                  >
                    {application.duration}
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.neutral_50}
                  padding="8px 16px"
                  gap="4px"
                  fillWidth
                >
                  <TableStatusColorCircleIcon status={application.status} />
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={
                      application.status === 'Pending'
                        ? colors.pending_yellow
                        : application.status === 'Confirmed'
                        ? colors.success_green
                        : application.status === 'Denied'
                        ? colors.error
                        : colors.base_black
                    }
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="16.8px"
                    align="center"
                  >
                    {application.status}
                  </Text>
                </EmployeeDetailDocumentTableHeader>
              </EmployeeDetailDocumentTableHeaders>
            ))}
          </EmployeeDetailDocumentTable>
        </>
      )}
      {timeOffApplicationVisible && (
        <AddEmployeeTimeoffRequest
          onClose={(success: boolean) => handleClose(success)}
          employee={employee}
          closePopUp={closePopUp}
        />
      )}
      {popUpVisible && (
        <MessagePopup text="Time off request created" type="success" />
      )}
    </EmployeeDetailsWrapper>
  );
};

export default EmployeeDayOffTab;
