import React, { Dispatch, SetStateAction } from 'react';
import { Company } from '../../../../app-router/component-props';
import {
  StyledCompanyDataGrid,
  StyledCompanyDataGridItem,
  StyledCompanyDataWrapper,
  StyledCompanyImage,
  StyledCompanyInfoAndImageWrapper,
  StyledCompanyInfoWrapper,
  StyledEditBtn,
} from './company-info.styled';
import { SettingsEditIcon } from '../../../../icons/settings-edit-icon';
import { Text, font } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import CompanyDemoImage from '../../../../../assets/images/img_avatar.png';
import { SettingsEmailIcon } from '../../../../icons/settings-email-icon';
import { SettingsDescriptionIcon } from '../../../../icons/settings-description-icon';
import { SettingsWebsiteIcon } from '../../../../icons/settings-website-icon';
import { SettingsAddressIcon } from '../../../../icons/settings-address-icon';
import { SettingsPhoneIcon } from '../../../../icons/settings-phone-icon';
import { SettingsCountryIcon } from '../../../../icons/settings-country-icon';

interface PropsTypes {
  user: Company;
  setEditCompanyInfoVisible: Dispatch<SetStateAction<boolean>>;
}

const CompanyInfo: React.FC<PropsTypes> = ({
  user,
  setEditCompanyInfoVisible,
}) => {
  return (
    <StyledCompanyInfoWrapper>
      <StyledEditBtn onClick={() => setEditCompanyInfoVisible(true)}>
        <Text
          margin="0"
          fontFamily={font.family}
          fontSize={14}
          fontWeight={700}
          color={colors.base_black}
        >
          Edit
        </Text>
        <SettingsEditIcon />
      </StyledEditBtn>
      <StyledCompanyInfoAndImageWrapper>
        <StyledCompanyImage src={user.profile_picture || CompanyDemoImage} />
        <StyledCompanyDataWrapper>
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={36}
            fontWeight={700}
            color={colors.neutral_900}
          >
            {user.name}
          </Text>
          <StyledCompanyDataGrid>
            <StyledCompanyDataGridItem>
              <SettingsDescriptionIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.description ? user.description : '/'}
              </Text>
            </StyledCompanyDataGridItem>
            <StyledCompanyDataGridItem>
              <SettingsEmailIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.email ? user.email : '/'}
              </Text>
            </StyledCompanyDataGridItem>
            <StyledCompanyDataGridItem>
              <SettingsAddressIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.address ? user.address : '/'}
              </Text>
            </StyledCompanyDataGridItem>
            <StyledCompanyDataGridItem>
              <SettingsWebsiteIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.website ? user.website : '/'}
              </Text>
            </StyledCompanyDataGridItem>
            <StyledCompanyDataGridItem>
              <SettingsPhoneIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.phone_number ? user.phone_number : '/'}
              </Text>
            </StyledCompanyDataGridItem>
            <StyledCompanyDataGridItem>
              <SettingsCountryIcon />
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.neutral_700}
              >
                {user && user.country ? user.country : '/'}
              </Text>
            </StyledCompanyDataGridItem>
          </StyledCompanyDataGrid>
        </StyledCompanyDataWrapper>
      </StyledCompanyInfoAndImageWrapper>
    </StyledCompanyInfoWrapper>
  );
};

export default CompanyInfo;
