import React from 'react';
import {
  TableContainer,
  TableCell,
  TableHeader,
  TableRow,
  HeaderCell,
} from './comparison-table.styled';
import { colors } from '../../../../../../style-utils/colors';

interface Candidate {
  name: string;
  skills: string;
  experience: string;
  qualifications: string;
  culturalFit: string;
  results: string;
  key_skills?: string;
  certifications?: string;
  past_experience?: string;
  career_progression?: string;
}

const sections: (keyof Candidate)[] = [
  'skills',
  'experience',
  'qualifications',
  'culturalFit',
  'results',
  'key_skills', // Optional field
  'certifications', // Optional field
  'past_experience', // Optional field
  'career_progression', // Optional field
];

const formatSectionTitle = (section: keyof Candidate): string => {
  const result = section.replace(/([A-Z])/g, ' ');

  return result.charAt(0).toUpperCase() + result.slice(1);
};

interface ComparisonTableProps {
  comparisonData: Candidate[];
}

export const ComparisonTable: React.FC<ComparisonTableProps> = ({
  comparisonData,
}) => {
  return (
    <TableContainer>
      <TableHeader>
        <HeaderCell bgColor={colors.secondary_500} fontColor="white">
          AI-Driven Insights
        </HeaderCell>

        {comparisonData?.map((candidate) => (
          <HeaderCell
            key={candidate.name}
            bgColor={colors.primary_500}
            fontColor="white"
          >
            {candidate.name}
          </HeaderCell>
        ))}
      </TableHeader>

      {sections.map((section, i) => (
        <TableRow key={section}>
          <TableCell
            fontColor="white"
            bgColor={i === 4 ? colors.primary_500 : colors.secondary_400}
          >
            {formatSectionTitle(section)}
          </TableCell>
          {comparisonData?.map((candidate) => (
            <TableCell bgColor="white" key={`${candidate.name}-${section}`}>
              {candidate[section] ?? 'N/A'}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableContainer>
  );
};
