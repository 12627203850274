// Candidates.tsx

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
  ContentWrapperCandidates,
} from '../../../layout/layout-container.styled';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { CandidatesTable } from './candidates-table';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { Chat } from '../../../chat/chat';
import { Overlay } from '../../job-board/job-board.styled';
import styled from 'styled-components';
import { ComparisonModal } from './comparison-modal/comparison-modal';
import { fetchAllItems } from '../../../requests/requests';
import { Candidate } from './comparison-modal/interfaces/candidate-interface';
import { Job } from './comparison-modal/interfaces/job-interface';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';

const ComparisonContainer = styled.div<{ isVisible: boolean }>`
  width: 800px;
  height: fit-content;
  background: white;
  z-index: 69;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;
  gap: 24px;
`;

interface CandidatesComponentProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const Candidates: React.FC<CandidatesComponentProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();
  const [comparisonMode, setComparisonMode] = useState(false);
  const comparisonRef = useRef<HTMLDivElement | null>(null);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  // eslint-disable-next-line
  const [comparisonData, setComparisonData] = useState<any>();
  const { id } = user;

  const handleComparison = () => {
    setComparisonMode(!comparisonMode);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      comparisonRef.current &&
      !comparisonRef.current.contains(event.target as Node)
    ) {
      setComparisonMode(false);
    }
  };

  useEffect(() => {
    if (comparisonMode) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [comparisonMode]);

  const getCandidatesFromDB = async () => {
    let response;
    if (id) {
      response = await fetchAllItems(`/applicants/${id}`);
    }

    setCandidates(response as Candidate[]);
  };

  const getJobsFromDb = async () => {
    let response;
    if (id) {
      response = await fetchAllItems(`company/jobs/all/${id}`);
    }

    setJobs(response as Job[]);
  };

  useEffect(() => {
    getCandidatesFromDB();
    getJobsFromDb();
    setComparisonData(null);
  }, [id]);

  const handleSelectCandidate = useCallback(
    (candidate: Candidate) => {
      const isAlreadySelected = selectedCandidates.some(
        (c) => c.id === candidate.id
      );

      if (isAlreadySelected) {
        setSelectedCandidates(
          selectedCandidates.filter((c) => c.id !== candidate.id)
        );
      } else {
        if (selectedCandidates.length < 2) {
          setSelectedCandidates([...selectedCandidates, candidate]);
        } else {
          const newSelected = [selectedCandidates[1], candidate];
          setSelectedCandidates(newSelected);
        }
      }
    },
    [selectedCandidates]
  );

  const handleSelectJob = useCallback((job: Job | null) => {
    setSelectedJob(job);
  }, []);

  const handleCompare = async () => {
    if (selectedCandidates.length === 2 && selectedJob) {
      setLoading(true);
      setError('');
      setComparisonData(null);

      try {
        const response = await axios.post(`${apiRoute}/compare-candidates`, {
          candidate1: selectedCandidates[0],
          candidate2: selectedCandidates[1],
          jobDescription: selectedJob,
        });

        if (response.data && response.data.comparison) {
          setComparisonData(response.data.comparison);
        } else {
          setError('Invalid response from server.');
        }
      } catch (err) {
        console.error(err);
        setError(err as string);
      } finally {
        setLoading(false);
      }
    } else {
      setError('Please select two candidates and a job to compare.');
    }
  };

  return (
    <>
      <LayoutContainer height={height}>
        <TopMenu navigationUrl={'/admin'} user={user} setUser={setUser} />
        {comparisonMode && <Overlay />}
        <MainContentWrapper>
          <Sidebar
            setUser={setUser}
            company={user}
            selectedOption={''}
            navigate={navigate}
          />
          {comparisonMode && (
            <ComparisonContainer isVisible={comparisonMode} ref={comparisonRef}>
              <ComparisonModal
                jobs={jobs}
                candidates={candidates}
                handleComparison={handleCompare}
                selectedCandidates={selectedCandidates}
                selectedJobPosition={selectedJob}
                handleSelectCandidate={handleSelectCandidate}
                handleSelectJobPosition={handleSelectJob}
                comparisonData={comparisonData}
                loading={loading}
                error={error}
              />
            </ComparisonContainer>
          )}
          <ContentWrapperCandidates>
            <NavbarAndContentContainer>
              <CandidatesTable
                handleComparison={handleComparison}
                company={user}
                navigate={navigate}
              />
            </NavbarAndContentContainer>
          </ContentWrapperCandidates>
        </MainContentWrapper>
        <Chat />
      </LayoutContainer>
    </>
  );
};
