import { useEffect, useState } from 'react';
import { SubscriptionPlanNew } from '../../../super-admin/subscription-plans/interface';
import { loadSubscriptionPlansNew } from '../../../../../function-utils/subscription-plan-utils';

interface UseSubscriptionPlansProps {
  initialPlans?: SubscriptionPlanNew[]; // Optional preloaded plans
}

interface UseSubscriptionPlansResult {
  plans: SubscriptionPlanNew[];
  sortedFeatures: string[];
  loading: boolean;
  error: string | null;
}

export const useSubscriptionPlans = ({
  initialPlans,
}: UseSubscriptionPlansProps): UseSubscriptionPlansResult => {
  const [plans, setPlans] = useState<SubscriptionPlanNew[]>([]);
  const [sortedFeatures, setSortedFeatures] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      setError(null);
      try {
        const fetchedPlans = initialPlans || (await loadSubscriptionPlansNew());
        const activePlans = fetchedPlans.filter(
          (plan) => plan.status === 'active'
        );
        const sortedPlans = activePlans.sort(
          (a, b) => parseFloat(a.price.amount) - parseFloat(b.price.amount)
        );
        setPlans(sortedPlans);

        // Extract unique features
        const allFeatures = Array.from(
          new Set(
            sortedPlans.flatMap((plan) => plan.features.map((f) => f.name))
          )
        );
        setSortedFeatures(allFeatures);
      } catch (err) {
        setError('Failed to fetch subscription plans');
        console.error('Error fetching subscription plans:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [initialPlans]);

  return { plans, sortedFeatures, loading, error };
};
