import React from 'react';

interface CheckOnOffCircleIconsProps {
  isChecked: boolean;
}

export const CheckOnOffCircleIcons: React.FC<CheckOnOffCircleIconsProps> = ({
  isChecked,
}) => {
  return isChecked ? (
    // CheckOn Icon
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6029_3230)">
        <path
          d="M14.8333 7.88674V8.50007C14.8325 9.93769 14.367 11.3365 13.5062 12.488C12.6454 13.6394 11.4355 14.4817 10.0569 14.8893C8.67829 15.297 7.20484 15.248 5.85631 14.7498C4.50779 14.2516 3.35644 13.3308 2.57397 12.1248C1.79151 10.9188 1.41986 9.49212 1.51445 8.05762C1.60904 6.62312 2.1648 5.25762 3.09885 4.16479C4.0329 3.07195 5.29519 2.31033 6.69746 1.9935C8.09973 1.67668 9.56685 1.82163 10.88 2.40674"
          stroke="#5789C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8334 3.1665L8.16675 9.83984L6.16675 7.83984"
          stroke="#5789C2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6029_3230">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.166748 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    // CheckOff Icon
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6029_3321)">
        <path
          d="M8.16667 15.1666C11.8486 15.1666 14.8333 12.1818 14.8333 8.49992C14.8333 4.81802 11.8486 1.83325 8.16667 1.83325C4.48477 1.83325 1.5 4.81802 1.5 8.49992C1.5 12.1818 4.48477 15.1666 8.16667 15.1666Z"
          stroke="#F26954"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1667 6.5L6.16675 10.5"
          stroke="#F26954"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.16675 6.5L10.1667 10.5"
          stroke="#F26954"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6029_3321">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.166748 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
