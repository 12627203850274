import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import axios from 'axios';
import { apiRoute } from '../constants/api-constants';
import { Notification } from '../components/hooks/use-notifications/interfaces';

interface Preferences {
  receive_email_notifications: boolean;
  receive_in_app_notifications: boolean;
  receive_edit_notifications: boolean;
  receive_time_off_notifications: boolean;
}

interface NotificationsContextProps {
  notifications: Notification[];
  preferences: Preferences;
  fetchNotifications: (userId: string) => void;
  createNotification: (
    notification: Omit<Notification, 'id' | 'read' | 'createdAt'>
  ) => void;
  updateNotification: (id: number, updatedData: Partial<Notification>) => void;
  deleteNotification: (id: number) => void;
  markAsRead: (id: number) => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  refreshPreferences: (userId: string) => void;
}

export const NotificationsContext = createContext<
  NotificationsContextProps | undefined
>(undefined);

export const useNotifications = (): NotificationsContextProps => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsProvider'
    );
  }

  return context;
};

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [preferences, setPreferences] = useState<Preferences>({
    receive_email_notifications: true,
    receive_in_app_notifications: true,
    receive_edit_notifications: true,
    receive_time_off_notifications: true,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchNotifications = async (userId: string) => {
    setIsLoading(true);
    try {
      const response = await axios.get<Notification[]>(
        `${apiRoute}/notifications/user/${userId}`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const createNotification = async (
    notification: Omit<Notification, 'id' | 'read' | 'createdAt'>
  ) => {
    setIsLoading(true);
    try {
      const response = await axios.post<Notification>(
        `${apiRoute}/notifications`,
        notification
      );
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        response.data,
      ]);
    } catch (error) {
      console.error('Error creating notification:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateNotification = async (
    id: number,
    updatedData: Partial<Notification>
  ) => {
    setIsLoading(true);
    try {
      const response = await axios.put<Notification>(
        `${apiRoute}/notifications/${id}`,
        updatedData
      );
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id ? response.data : notification
        )
      );
    } catch (error) {
      console.error('Error updating notification:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteNotification = async (id: number) => {
    setIsLoading(true);
    try {
      await axios.delete(`${apiRoute}/notifications/${id}`);
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    } catch (error) {
      console.error('Error deleting notification:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const markAsRead = async (id: number) => {
    setIsLoading(true);
    try {
      await axios.put(`${apiRoute}/notifications/${id}/read`);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPreferences = useCallback(async (userId: string) => {
    try {
      const response = await axios.get<Preferences>(
        `${apiRoute}/${userId}/notification-preferences`
      );
      console.log('RESPONSE DATA OD REFERSH PREFERENCES:::::');
      console.log(response.data);
      setPreferences(response.data);
    } catch (error) {
      console.error('Error fetching preferences:', error);
    }
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        fetchNotifications,
        createNotification,
        updateNotification,
        deleteNotification,
        markAsRead,
        refreshPreferences,
        // createNotification: async () => {},
        // updateNotification: async () => {},
        // deleteNotification: async () => {},
        // markAsRead: async () => {},
        isLoading,
        setIsLoading,
        preferences,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
